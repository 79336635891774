/* eslint-disable */

import React, { useEffect, useState } from 'react';
import TripReorder from './trip-order';
import dayjs from 'dayjs';
import { getOptimizedMultipleFlexTrip, getOptimizedRouteTravelData } from '../../../services/dispatch';
import Loader from '../../../components/LayoutComponents/Loader';
import OptimizeTrip from './optimize-trip';

const MultiLoad = ({ bookings, close, driver, setSpinner, unsetSpinner, vehicleTrips }) => {
  const [view, setView] = useState('');
  const [stopOrder, setStopOrder] = useState([]);
  const [anchorLocation, setAnchorLocation] = useState();
  const [optimizedTime, setOptimizedTime] = useState([]);

  const handleClose = () => {
    setView('');
    close();
  };

  const lookForTripBookings = () => {
    // Function to parse time with today's date context
    const parseTime = (timeString) => {
      return dayjs(dayjs().format('YYYY-MM-DD') + ' ' + timeString, ['YYYY-MM-DD h:mm A', 'YYYY-MM-DD H:mm']);
    };

    // Find the earliest pickup_time in var2
    const earliestVar2Time = bookings.reduce((earliest, item) => {
      const parsedTime = parseTime(item.pickup_time);
      return !earliest || parsedTime.isBefore(earliest) ? parsedTime : earliest;
    }, null);

    // Check var1 for an earlier first_pickup_time
    let addressWithEarlierTime = null;

    for (const entry of vehicleTrips) {
      for (const trip of entry.trips) {
        const tripTime = parseTime(trip.first_pickup_time);
        if (tripTime.isBefore(earliestVar2Time)) {
          addressWithEarlierTime = trip.last_dropoff_address;
          break;
        }
      }
    }
    const anchor = addressWithEarlierTime || null;
    setAnchorLocation(anchor);
    handleGetOptimizedTrips(anchor);
  };

  const fixBookingData = (bookingsData) => {
    const newBookings = [];
    bookingsData.forEach((bookings) => {
      if (bookings.bookings.length > 1) {
        bookings.bookings.forEach((booking) => {
          newBookings.push({
            ...bookings,
            bookings: [booking],
            isMoved: true,
          });
        });
      } else {
        newBookings.push(bookings);
      }
    });

    setStopOrder(newBookings);
  };

  const handleGetOptimizedTrips = async (anchor) => {
    try {
      setSpinner();

      const params = {
        bookings: bookings.map(({ id }) => id),
        shuttle_anchor_location: anchor,
        shuttle_id: driver.shuttle_id,
      };

      const resp = await getOptimizedMultipleFlexTrip(params);

      if (resp.status === 'success') {
        fixBookingData(resp.data?.stop_order);
        setView('tripOrder');
      } else {
        throw Error(result.message.message);
      }
    } catch (e) {
      console.error(e);
    }
    unsetSpinner();
  };

  const handleGetOptimizedTravelRoute = async (stopOrder) => {
    try {
      setSpinner();
      const params = {
        stop_order: stopOrder,
      };
      const resp = await getOptimizedRouteTravelData(params);
      if (resp.status === 'success') {
        setOptimizedTime(resp.data);
        setView('optimizeTrip');
      } else {
        throw Error(result.message.message);
      }
      console.log(resp);
    } catch (error) {
      console.error(error);
    }

    unsetSpinner();
  };

  useEffect(() => {
    lookForTripBookings();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>
        {view === 'tripOrder' && (
          <TripReorder close={handleClose} bookings={stopOrder} handleNext={handleGetOptimizedTravelRoute} />
        )}
        {view === 'optimizeTrip' && (
          <OptimizeTrip close={handleClose} optimizedTime={optimizedTime} handelBack={() => setView('tripOrder')} />
        )}
      </div>
    </div>
  );
};

export default MultiLoad;
