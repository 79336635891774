/* eslint-disable react/no-array-index-key */

import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { withGoogleMap, GoogleMap, DirectionsRenderer, Marker, InfoWindow } from 'react-google-maps';
import styles from './styles.module.scss';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);

const url = `https://maps.googleapis.com/maps/api/js?key=${
  process.env.REACT_APP_GOOGLEAPIKEY
}&v=3.exp&libraries=geometry,drawing,places`;

const pickupIconMarker = new window.google.maps.MarkerImage(
  `${process.env.PUBLIC_URL}/resources/images/pickup_pin_same-size.png`,
  null,
  null,
  null,
  null
);

const dropoffIconMarker = new window.google.maps.MarkerImage(
  `${process.env.PUBLIC_URL}/resources/images/dropoff_pin_same-size.png`,
  null,
  null,
  null,
  null
);

const customStyles = [
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.school',
    elementType: 'labels.text',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.business',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }], // Disables icons for POIs, like business markers
  },
];

const DirectionMap = withGoogleMap(({ directions, bookings }) => {
  const mapRef = useRef(null);

  // Fit bounds function
  const fitBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();

    bookings.map((booking, key) => {
      if (booking.geoLocation.latitude && booking.geoLocation.longitude) {
        const pickupCoordinate = new window.google.maps.LatLng(
          booking.geoLocation.latitude,
          booking.geoLocation.longitude
        );
        bounds.extend(pickupCoordinate);
      }

      if (bookings[key + 1] && bookings[key + 1].geoLocation.latitude && bookings[key + 1].geoLocation.longitude) {
        const nextTrip = new window.google.maps.LatLng(
          bookings[key + 1].geoLocation.latitude,
          bookings[key + 1].geoLocation.longitude
        );
        bounds.extend(nextTrip);
      }

      return booking;
    });
    setTimeout(() => {
      if (mapRef.current) {
        mapRef.current.fitBounds(bounds);
      }
    }, 500);
  };

  useEffect(() => {
    if (bookings.length) {
      fitBounds();
    }

    // eslint-disable-next-line
  }, [bookings, directions]);

  return (
    <GoogleMap
      ref={mapRef}
      mapTypeId="roadmap"
      options={{
        zoomControl: true,
        mapTypeControl: false,
        fullscreenControl: false,
        scaleControl: false,
        streetViewControl: false,
        controlSize: 27,
        styles: customStyles,
        trackViewChanges: false,
      }}
      onClick={(event) => {
        if (event.placeId) {
          // Prevent the default action if the click is on an address or POI
          event.stop();
        }
      }}
    >
      {directions &&
        directions.map((direction, key) => {
          const offset = 0.00001;
          const latOffset = offset * (Math.random() < 0.5 ? -1 : 1) * (key + 1); // Apply offset based on index
          const lngOffset = offset * (Math.random() < 0.5 ? -1 : 1) * (key + 1); // Apply offset based on index

          const isPickUp = bookings[key].bookings[0].stop_type === 'pickup';
          const markerIcon = isPickUp ? pickupIconMarker : dropoffIconMarker;
          const name = `${bookings[key].bookings[0].passenger_firstname} ${
            bookings[key].bookings[0].passenger_lastname
          }`;
          const time = isPickUp
            ? dayjs(bookings[key].bookings[0].scheduled_pickup_time).format('hh:mm A')
            : dayjs(bookings[key].bookings[0].scheduled_dropoff_time).format('hh:mm A');
          return (
            <React.Fragment key={key}>
              <DirectionsRenderer
                directions={direction}
                options={{
                  polylineOptions: {
                    strokeColor: '#000',
                    strokeOpacity: 1,
                    strokeWeight: key === 0 ? 6 : 3,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    zIndex: key === 0 ? 999 : 9999,
                  },
                  preserveViewport: true,
                  suppressMarkers: true,
                }}
              />

              <Marker
                position={{
                  lat: bookings[key].geoLocation.latitude + latOffset,
                  lng: bookings[key].geoLocation.longitude + lngOffset,
                }}
                zIndex={999}
                icon={markerIcon}
              >
                <InfoWindow>
                  <span style={{ textAlign: 'left', fontWeight: 'bold' }}>
                    <div>{time}</div>
                    <div>{name}</div>
                  </span>
                </InfoWindow>
              </Marker>
            </React.Fragment>
          );
        })}
    </GoogleMap>
  );
});

const TripsOnMap = ({ bookings }) => {
  const [directions, setDirections] = useState([]);

  const DirectionsService = new window.google.maps.DirectionsService({
    suppressMarkers: true,
  });

  const getRoutes = () => {
    bookings.map((booking, key) => {
      const origin = bookings[key];
      const destination = bookings[key + 1] ? bookings[key + 1] : origin;
      DirectionsService.route(
        {
          origin: new window.google.maps.LatLng(origin.geoLocation.latitude, origin.geoLocation.longitude),
          destination: new window.google.maps.LatLng(
            destination.geoLocation.latitude,
            destination.geoLocation.longitude
          ),
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            directions.push(result);
            setDirections([...directions]);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );

      return booking;
    });
  };

  useEffect(() => {
    try {
      getRoutes();
    } catch (e) {
      console.error('Component Error', e);
    }

    // eslint-disable-next-line
  }, [bookings]);

  return (
    <>
      {directions && directions.length > 0 ? (
        <DirectionMap
          directions={directions}
          bookings={bookings}
          googleMapURL={url}
          loadingElement={<div className={styles.mapContainerArea} />}
          containerElement={<div className={styles.mapContainerArea} />}
          mapElement={<div style={{ height: `100%`, width: '100%' }} />}
        />
      ) : null}
    </>
  );
};

export default TripsOnMap;
