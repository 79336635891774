/* eslint-disable */
import React, { useEffect, useState, useLayoutEffect, useContext } from 'react';

import { collection, where, query } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { isEmpty } from 'lodash';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

import { Button, notification } from 'antd';

import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

import { PdfTemplate } from '../drivers/pdfTemplate';
import DriversWorkingHours from './drivers-schedule';
import BookingDetails from './booking-details';

import './color-status.css';
import './index.css';

import {
  getAssignedVehicleTripList,
  updateDispatchTrip,
  removeScheduledTrip,
  updateSingleBookingTrip,
  createNewSingleFlexTrip,
} from '../../../services/dispatch';
import {
  fetchFleetScheduleDataWithAutoCreate,
  fetchFleetScheduleData,
  getFleetLatestScheduleDate,
  saveFleetScheduleData,
  patchTimeBlock,
  saveLunchBreak,
  saveDriverAction,
} from '../../../services/drivers';

import FleetRouteCompare from './fleet-routes-compare';
import {
  hours,
  riseStatus,
  itemBackground,
  rideStatus,
  bookingWidth,
  hourHeightInPixel,
  converterToHeight,
  draggableBooking,
  setTimeWithDate,
  dateRangeValid,
  convertTime,
} from './constant';
import UpdateBookingTripTime from './update-booking-trip-time';
import { editAdminNote, getAvailableBookingDates } from '../../../services/rider-services/bookings';
import TimeBlockModal from './timeblock';
import { firestore } from '../../../lib/firebase';
import LunchBreakModal from './LunchBreak';
import { getHeatmapColor } from '../../../lib/heatmap';
import HeatMapInfo from './heatmap-info';
import TimeBlockDetails from './timeblock-details';
import LunchBreakDetails from './lunchbreak-details';
import DriverActionDetails from './driveraction-details';
import DriverAction from './driver-actions';
import LoaderVersionOne from '../../../components/Loaders/VersionOne';
import { ToggleSwipeContext } from '../../../hooks/useIsMobile';
import { addSeconds, convertTo12HourFormat } from '../../../lib/common';
import DriverInfo from './driver-info';
import VehicleInfo from './vehicle-info';

dayjs.extend(utc);
dayjs.extend(timezone);

const tripWidth = 198; /* 
1. this is used to identify where is the touch/dragged trip is dropped,
2. touch and drop needs computation since by default this is not supported on most browsers.
*/

const displayNotification = (description) => {
  notification.warning({
    message: 'Alert',
    description,
  });
};
const env = window._env_.DEPLOYMENT_PROFILE;

const TrackingDomain = env === 'dev' || env === 'preDev' ? 'https://dev.duetride.co/' : 'https://duetride.co/';

const SchedulerComponent = ({
  updatedAddedVehicles,
  updateVehicle,
  searchValue,
  vehicleLists,
  selectedDate,
  setSpinner,
  unsetSpinner,
  onClickDriverTxt,
  addedVehicle,
  bookings,
  updateBooking,
  updateBookingList,
  refreshed,
  programId,
  setTripsList,
  closeBookingTimeModal,
  usedForTemplate,
  isShowDriverRouteCompare,
  setShowDriverRouteCompare,
  preSelectedBooking,
  tripsOfEachVehicle,
  selectedProgram,
  heatMapProps,
  showHeatMap,
  isShowHeatMapMap,
  hideHeatMapMap,
  isMobileView,
  autoScheduleData: autoScheDuleDataProps,
  movedAutoScheduleData, // auto schedule data moved to trips
  clearMovedAutoScheduleData,
  updateAutoScheduleData,
  isNewScheduler,
  tripRecommendationData: propsTripRecommendationData,
}) => {
  const Zone = dayjs.tz.guess();
  const [autoScheduleData, setAutoScheduleData] = useState([]);
  const [vehicleTrips, setVehicleTrips] = useState([]);
  const [availableTBookingTime, setAvailableTBookingTime] = useState(null);
  const [addedKeys, setAddedKeys] = useState([]);
  const [viewedVehicleTrip, setViewedVehicleTrip] = useState({});
  const [viewedBooking, setViewBooking] = useState(null);
  const [currentViewedCar, setCurrentViewedCar] = useState('');
  const [removeBookingId, setRemoveBookingId] = useState(null);
  const [driverSchedules, setDriveSchedules] = useState([]);
  const [isShowRouteCompare, setIsShowRouteCompare] = useState(false);
  const [timeNowScrolled, setTimeNowScrolled] = useState(false);
  const [isShowTimeNow, setIsShowTimeNow] = useState(false);
  const [isFetchingList, setIsFetchingList] = useState(true);
  const [isDownloadTripPdf, setIsDownloadTripPdf] = useState(false);
  const [TripPdfDetails, setTripPDfDetails] = useState({});
  const [scheduleId, setScheduleId] = useState('');
  const [timeNowLine, setTimeLineNow] = useState(null);
  const [addedBookingIds, setAddedBookingIds] = useState([]);
  const [showUpdateBookingTripTime, setShowUpdateBookingTripTime] = useState(false);
  const [showTimeBlockModal, setShowTimeBlockModal] = useState(false);
  const [showLunchBreakModal, setShowLunchBreakModal] = useState(false);
  const [showTrackingLink, setShowTrackingLink] = useState(null);
  const [timeBlockStart, setTimeBlockStart] = useState();
  const [timeBlockEnd, setTimeBlockEnd] = useState();
  const [timeBlockNotes, setTimeBlockNotes] = useState('');
  const [dashBoardWidth, setDashBoardWith] = useState();
  const [tripRecommendationData, setTripRecommendationData] = useState();
  // get shutte geolocation data
  const [shuttleQuery, setShuttleQuery] = useState(null);

  const [heatMap, setHeatMap] = useState({});
  const [xyPosition, setXYPosition] = useState({ x: 0, y: 0 });
  const [showTripMenu, setShowTripMenu] = useState([]);
  const [showDriverAction, setShowDriverAction] = useState(false);

  const { toggleSwipe } = useContext(ToggleSwipeContext);

  useEffect(() => {
    if (movedAutoScheduleData?.value) {
      // structure the booking to make it same as the trips booking structure
      const newTrips = [
        ...movedAutoScheduleData.value.trips.map((trip) => {
          trip.trip_id = Math.random();

          delete trip.from; // this object key is used to identify where the data came from, (from clicking of shuttle or driver), we don't need this here

          trip.bookings = [
            ...trip.bookings.map((booking) => {
              delete booking.type;
              delete booking.booking_ids;
              // copy data that are not in the current bookings state
              booking.ride_status = 1;
              booking.id = booking.booking_id;
              if (!booking.trip_id) {
                const index = bookings.findIndex(({ booking_id }) => booking_id === booking.booking_id);

                if (index > -1) {
                  booking.trip_id = bookings[index].trip_id;
                }
              }

              return booking;
            }),
          ];

          return trip;
        }),
      ];

      if (movedAutoScheduleData.isMerge) {
        // find the column in existing vehicleTrips and add the trips
        const column = vehicleTrips.findIndex(
          (item) =>
            item.shuttle_id === movedAutoScheduleData.value.shuttle_id &&
            item.driver_id === movedAutoScheduleData.value.driver_id &&
            item.driver_team_id === movedAutoScheduleData.value.driver_team_id
        );

        if (column > -1) {
          const newTripArray = vehicleTrips[column].trips.concat(newTrips);
          vehicleTrips[column].trips = [...newTripArray];

          const newV = addedVehicle[column].trips.concat(newTrips);
          addedVehicle[column].trips = [...newV];
        }
      } else {
        // just add the vehicle to the trips
        vehicleTrips.push(movedAutoScheduleData.value);
        addedVehicle.push(movedAutoScheduleData.value);
      }

      let cleanArray = vehicleTrips.filter(
        (trip, index, self) =>
          index ===
          self.findIndex(
            (trip2) =>
              trip2.driver_id === trip.driver_id &&
              trip2.driver_team_id === trip.driver_team_id &&
              trip2.shuttle_id === trip.shuttle_id
          )
      );

      cleanArray = [
        ...cleanArray.filter(
          (trip) =>
            (trip.driver_id !== null && trip.shuttle_id !== null) ||
            (trip.driver_team_id !== null && trip.shuttle_id !== null)
        ),
      ];

      setVehicleTrips([...cleanArray]);
      updateVehicle([...addedVehicle]);
      clearMovedAutoScheduleData();
    }
  }, [movedAutoScheduleData]);

  useEffect(() => {
    setAutoScheduleData(autoScheDuleDataProps);
  }, [autoScheDuleDataProps]);

  // generate heatmap data for each program
  useEffect(() => {
    setHeatMap({ ...heatMapProps });
  }, [heatMapProps]);

  useEffect(() => {
    if (propsTripRecommendationData && propsTripRecommendationData.newTrip?.booking_id) {
      setTripRecommendationData(propsTripRecommendationData);
    } else if (propsTripRecommendationData?.reset) {
      // remove all recommendationTrip
      const removeRecommendationTrips = vehicleTrips.map((shuttleData) => ({
        ...shuttleData,
        trips: [...shuttleData.trips?.filter((trip) => !trip.recommendation)],
      }));

      //

      const newTripList = [...removeRecommendationTrips.map((data) => data)];

      setTripsList([...newTripList]);
      updateVehicle([...newTripList]);
      setVehicleTrips([...newTripList]);
    }
  }, [propsTripRecommendationData]);

  // trip recommendation logics
  useEffect(() => {
    if (tripRecommendationData && tripRecommendationData.newTrip.booking_id) {
      // reconstruct the booking into a temporary trip, add all properties needed
      const { newTrip, sortedRecommendedTrip, vehicleIndex } = tripRecommendationData;

      // look for the recommended vehicle and insert newTrip
      const { driver_id, shuttle_id, driver_team_id } = sortedRecommendedTrip[vehicleIndex];
      let index = vehicleTrips.findIndex(
        (trip) =>
          trip.driver_id === driver_id && trip.shuttle_id === shuttle_id && trip.driver_team_id === driver_team_id
      );

      if (index > -1) {
        const tripId = vehicleTrips[index].trips[0]?.trip_id || Math.random();
        newTrip.recommendation = true;
        newTrip.trip_id = tripId;
        newTrip.drivershifts = vehicleTrips[index].trips[0]?.drivershifts || [];
        newTrip.driver_action = vehicleTrips[index].trips[0]?.driver_action || null;
        newTrip.trip_id = tripId;
        newTrip.driver_id = vehicleTrips[index].driver_id;
        newTrip.driver_team_id = vehicleTrips[index].driver_team_id;
        newTrip.driver_lastname = vehicleTrips[index].driver_lastname;
        newTrip.driver_firstname = vehicleTrips[index].driver_firstname;
        newTrip.driver_team_name = vehicleTrips[index].driver_team_name;
        newTrip.driver_team = vehicleTrips[index].driver_team;
        newTrip.ride_status = 0;

        newTrip.id = newTrip.booking_id;
        newTrip.booking_ids = [newTrip.booking_id];

        newTrip.first_pickup_time = newTrip.pickup_time;
        newTrip.last_dropoff_time = addSeconds(newTrip.pickup_time, newTrip.travel_time);
        newTrip.scheduled_pickup_time = convertTo12HourFormat(newTrip.pickup_time);
        newTrip.scheduled_dropoff_time = convertTo12HourFormat(addSeconds(newTrip.pickup_time, newTrip.travel_time));

        const bookingsInfo = { ...newTrip };

        newTrip.bookings = [bookingsInfo];

        newTrip.bookings[0].blockType = 'trip';
        newTrip.bookings[0].rec = true;

        // prevent duplicates
        // remove other recommendation
        let newTripList = [...vehicleTrips.map((data) => data)];
        const removeRecommendationTrips = newTripList.map((shuttleData) => ({
          ...shuttleData,
          trips: [...shuttleData.trips.filter((trip) => !trip.recommendation)],
        }));

        index = removeRecommendationTrips.findIndex(
          (trip) =>
            trip.driver_id === driver_id && trip.shuttle_id === shuttle_id && trip.driver_team_id === driver_team_id
        );

        removeRecommendationTrips[index].trips.push(newTrip);
        newTripList = [...removeRecommendationTrips.map((data) => data)];

        setTripsList([...newTripList]);
        updateVehicle([...newTripList]);
        setVehicleTrips([...newTripList]);
      }
      // scroll to view
      setTimeout(() => {
        const element = document.getElementById(newTrip.booking_id);
        if (element) {
          element.style.scrollBehavior = 'smooth';
          element.scrollIntoView({
            block: 'center',
            inline: 'nearest',
            behavior: 'smooth',
          });
        }
      }, 800);
    }
  }, [tripRecommendationData]);

  useEffect(() => {
    setDashBoardWith(document.getElementById('dispatch-board').scrollWidth);
    return () => {
      manageLocalStorage('draggedItem', 'remove');
      toggleSwipe(true);
    };
  }, []);

  useEffect(() => {
    if (viewedBooking && viewedBooking.trip) {
      // set default value
      const { trip } = viewedBooking;
      const { first_pickup_time, last_dropoff_time, notes } = trip;
      setTimeBlockNotes(notes);
      setTimeBlockStart(Math.ceil(convertTime(first_pickup_time)));
      setTimeBlockEnd(Math.ceil(convertTime(last_dropoff_time)));
    }
  }, [viewedBooking]);

  useEffect(() => {
    if (vehicleTrips.length) {
      setTripsList(vehicleTrips);
    }
  }, [vehicleTrips]);

  useEffect(() => {
    if (Object.keys(TripPdfDetails).length > 0) {
      setIsDownloadTripPdf(true);
    }
  }, [TripPdfDetails]);

  /**
   * this will be triggered when user adds a vehicle to the list
   */
  useEffect(() => {
    if (addedVehicle.length > 0 && !usedForTemplate) {
      combineAddedVehicle([...vehicleTrips], addedVehicle);
    }
  }, [addedVehicle, usedForTemplate]);

  useEffect(() => {
    if (usedForTemplate) {
      setVehicleTrips([...addedVehicle]);
      setIsFetchingList(false);
    } else {
      handleGetTripLists();
    }

    let now = new Date();
    const selectedD = new Date(`${selectedDate.toString()} 00:00`);

    if (selectedProgram && selectedProgram.program_timezone) {
      now = new Date(
        new Date().toLocaleString('en', {
          timeZone: selectedProgram.program_timezone,
        })
      );
    }

    setIsShowTimeNow(selectedD.getMonth() === now.getMonth() && selectedD.getDate() === now.getDate());
    setDashBoardWith(document.getElementById('dispatch-board').scrollWidth);
  }, [selectedDate, usedForTemplate]);

  useEffect(() => {
    if (isShowTimeNow && timeNowLine && !timeNowScrolled) {
      timeNowLine.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      });
      setTimeNowScrolled(true);
    } else {
      setTimeout(() => {
        const element = document.getElementById('scroll-7:00 am');
        element.style.scrollBehavior = 'smooth';
        element.scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }, 800);
    }
  }, [isShowTimeNow, timeNowLine]);

  /* reset */
  useEffect(() => {
    if (refreshed !== '') {
      getFreshData();
    }
  }, [refreshed]);

  const getFreshData = () => {
    setIsFetchingList(true);
    setVehicleTrips([]);
    setAddedKeys([]);
    handleGetTripLists();
    setShowTripMenu([]);
  };

  const handleUpdateAdminNote = async (booking, note) => {
    setSpinner();
    if (note === '') {
      setAvailableTBookingTime(null);
      unsetSpinner();
      return;
    }
    try {
      const params = {
        booking_id: booking.booking_id,
        admin_note: note,
        office_note: booking.office_note,
      };
      showTrackingLink;
      const resp = await editAdminNote(params);
      if (resp.status === 'success') {
        displayNotification('The changes are saved successfully.');
      } else {
        displayNotification(resp.message.message);
      }

      viewedBooking.admin_note = note;
      updateBookingList(viewedBooking);
      setAvailableTBookingTime(null);
      setShowUpdateBookingTripTime(false);
      setCurrentViewedCar('');
    } catch (e) {
      console.error(e);
    }

    unsetSpinner();
  };

  const handleGetAvailableBookingTime = async (booking) => {
    const { program_id, ride_status } = booking;
    setSpinner();
    const status = ride_status;

    try {
      if (status == 1 || status == 2) {
        const convertedDate = new Date(`${selectedDate.toString()}T00:00:00`);
        const year = convertedDate.getFullYear();
        const d = convertedDate.getDate();
        const month = convertedDate.getMonth();
        const day_of_month = d < 10 ? `0${d}` : d;

        const resp = await getAvailableBookingDates({
          program_id,
          day_of_month,
          month,
          year,
        });

        if (resp.status === 'success') {
          const buf = resp.data.available_booking_dates.map((elem) => {
            const timeElement = elem.time.map((time) => {
              return {
                val: time.booking_startTime,
                txt: `${dayjs(time.booking_startTime, 'YYYY-MM-DD HH:mm').format('h:mm A')} - ${dayjs(
                  time.booking_endTime,
                  'YYYY-MM-DD HH:mm'
                ).format('h:mm A')}`,
              };
            });

            return { date: elem.date, time: timeElement };
          });
          setAvailableTBookingTime(buf);
          setShowUpdateBookingTripTime(true);
        } else {
          setAvailableTBookingTime(null);
          setShowUpdateBookingTripTime(true);
        }
      } else {
        setAvailableTBookingTime(null);
        setShowUpdateBookingTripTime(true);
      }
    } catch (e) {
      console.error(e);
    }

    unsetSpinner();
  };

  const handleGetTripLists = async () => {
    try {
      setSpinner();

      const [result] = await Promise.all([
        getAssignedVehicleTripList({
          date: dayjs(selectedDate).format('MMMM D, YYYY'),
        }),
        getFleetSchedule(),
      ]);

      // const result = await getAssignedVehicleTripList({
      //   date: dayjs(selectedDate).format('MMMM D, YYYY'),
      // });
      let vTrips = result.data.vehicle_trip_list;
      if (usedForTemplate) {
        vTrips = vTrips.map((trip) => {
          trip.trips = [];

          return trip;
        });
      }

      setTripsList(result.data.vehicle_trip_list);
      updateVehicle(result.data.vehicle_trip_list);
      combineAddedVehicle([...vTrips.sort((a, b) => a.shuttle_name.localeCompare(b.shuttle_name))]);

      setIsFetchingList(false);

      // getFleetSchedule();
    } catch (e) {
      console.log(e);
    }
    setDashBoardWith(document.getElementById('dispatch-board').scrollWidth);
    unsetSpinner();
  };

  // get fleet schedule
  const getFleetSchedule = async (date = selectedDate) => {
    if (!usedForTemplate) {
      try {
        // setSpinner();
        const param = {
          date: `Today, ${dayjs(date).format('LL')}`,
          timezone: Zone,
        };

        const resp = await fetchFleetScheduleDataWithAutoCreate(param);

        if (resp.status === 'success') {
          setDriveSchedules(resp.data.driver_schedule_list);
          setScheduleId(resp.data.schedule_id);

          const allZero = resp.data.driver_schedule_list.map((schedule) => schedule.schedule_data.length === 0);

          if (!allZero.includes(false)) {
            getFleetLatestSchedule(resp.data);
          }
        } else {
          displayNotification(resp._message);
          setScheduleId('');
        }
      } catch (e) {
        console.error(e);
        setScheduleId('');
      }
      // unsetSpinner();
    }
  };
  // this will update changes of driver to other column
  // if the driver exist there
  const updateDriverInAllDriversArray = (drivers) => {
    setDriveSchedules(drivers);
  };

  const getFleetLatestSchedule = async (sched) => {
    if (!usedForTemplate) {
      try {
        const resp = await getFleetLatestScheduleDate();

        const params = {
          date: `${dayjs(resp.data.latest_schedule_date).format('LL')}`,
          timezone: Zone,
        };

        const schedulesTemplate = await fetchFleetScheduleData(params);

        if (schedulesTemplate.status === 'success') {
          setDriveSchedules(schedulesTemplate.data.driver_schedule_list);
          // copy template and save to db
          const params = {
            timezone: sched.timezone,
            schedule_id: sched.schedule_id,
            driver_schedule_list: [...schedulesTemplate.data.driver_schedule_list],
            date: sched.date.replace('Today, ', ''),
          };
          await saveFleetScheduleData(params);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleUpdateBooking = async ({ sTime, adminNote }) => {
    try {
      setSpinner();
      const time = sTime;
      let trip_id = viewedBooking.trip_id;
      if (viewedBooking.trip_id === null) {
        const trip = bookings.filter(({ booking_id }) => booking_id === viewedBooking.booking_id);

        if (trip.length > 0) {
          trip_id = trip[0].trip_id;
          viewedBooking.trip_id = trip_id;
          setViewBooking({ ...viewedBooking });
        }
      }

      const param = {
        trip_id,
        booking_id: viewedBooking.booking_id,
        program_id: viewedBooking.program_id,
        pickup_time: time.txt,
        admin_note: adminNote,
      };

      const resp = await updateSingleBookingTrip(param);

      if (resp.status == 'success') {
        // find trip
        let tripsIndex = vehicleTrips
          .map((trip, index) => {
            const found = trip.trips.find((t) => t.trip_id === viewedBooking.trip_id);
            if (found) {
              return index;
            }
          })
          .find((elem) => elem !== undefined);

        if (!tripsIndex || typeof tripsIndex === undefined) {
          tripsIndex = vehicleTrips.findIndex(
            (vehicle) =>
              vehicle.trips.findIndex(
                ({ bookings }) => bookings.findIndex(({ booking_id }) => booking_id === viewedBooking.booking_id) > -1
              ) > -1
          );
        }

        let tripObjIndex = vehicleTrips[tripsIndex].trips.findIndex((trip) => trip.trip_id === viewedBooking.trip_id);

        if (tripObjIndex === -1) {
          tripObjIndex = vehicleTrips[tripsIndex].trips.findIndex(
            (trip) => trip.bookings.findIndex(({ booking_id }) => booking_id === viewedBooking.booking_id) > -1
          );

          vehicleTrips[tripsIndex].trips[tripObjIndex].trip_id = trip_id;
          vehicleTrips[tripsIndex].trips = [...vehicleTrips[tripsIndex].trips];
        }

        const endTime = dayjs(`${selectedDate} ${vehicleTrips[tripsIndex].trips[tripObjIndex].last_dropoff_time}`);

        const startTIme = dayjs(`${selectedDate} ${vehicleTrips[tripsIndex].trips[tripObjIndex].first_pickup_time}`);

        const dateduration = dayjs.duration(dayjs(endTime).diff(dayjs(startTIme))).as('minutes');

        const startDateTime = dayjs(time.val.toString(), 'YYYY-MM-DDThh:mm:ssz');
        let endDateTime = dayjs(time.val.toString(), 'YYYY-MM-DDThh:mm:ssz');

        endDateTime = endDateTime.add(dateduration, 'minute');

        viewedBooking.pickup_time = startDateTime.format('h:mm A');
        viewedBooking.pickup_time_mil = startDateTime.format('H:mm');
        viewedBooking.scheduled_pickup_time = startDateTime.format('h:mm A');
        viewedBooking.scheduled_dropoff_time = endDateTime.format('h:mm A');
        viewedBooking.admin_note = adminNote;
        setViewBooking(viewedBooking);
        viewedBooking;
        const bookingIndex = vehicleTrips[tripsIndex].trips[tripObjIndex].bookings.findIndex(
          ({ booking_id }) => booking_id === viewedBooking.booking_id
        );

        vehicleTrips[tripsIndex].trips[tripObjIndex].bookings[bookingIndex] = viewedBooking;

        setVehicleTrips([...vehicleTrips]);
        closeBookingTimeModal();
        displayNotification('The changes are saved successfully.');
      } else {
        displayNotification(resp.message.message);
      }
      updateBookingList(viewedBooking);

      setCurrentViewedCar('');
      setShowUpdateBookingTripTime(false);
    } catch (e) {
      console.error(e);
    }

    unsetSpinner();
  };

  const removeTripFromList = (bookingId) => {
    const updatedVehicleTrips = [
      ...vehicleTrips.map((vehicle) => {
        const updatedTrips = vehicle.trips.map((trip) => {
          const cleanedBookings = trip.bookings.filter(({ booking_id }) => booking_id !== bookingId);

          trip.bookings = cleanedBookings;

          if (cleanedBookings.length === 0 && trip.booking_ids.length > 0) {
            return null;
          }

          return trip;
        });

        vehicle.trips = [...updatedTrips.filter((trip) => trip !== null)];
        return vehicle;
      }),
    ];

    setVehicleTrips([...updatedVehicleTrips]);
  };

  const removeDispatchedBooking = async () => {
    if (removeBookingId) {
      setSpinner();
      setRemoveBookingId(null);
      try {
        const param = { bookings: [removeBookingId] };
        const resp = await removeScheduledTrip(param);

        if (resp.status === 'success') {
          const element = resp.data.bookings[0];
          const restoreBookings = [
            ...bookings.map((elem) => {
              if (elem.booking_id == element.booking_id)
                return {
                  ...elem,
                  dispatched: false,
                  checked: false,
                  ride_status: 0,
                  status: 'Booked',
                };
              return elem;
            }),
          ];

          removeTripFromList(removeBookingId);
          updateBooking(restoreBookings, true);

          // updated addedBookingIds if a booking was removed
          const getIndex = addedBookingIds.indexOf(element.booking_id);

          if (getIndex > -1) {
            addedBookingIds.splice(getIndex, 1);
          }

          setAddedBookingIds([...addedBookingIds]);
        } else {
          displayNotification(
            "The trip can't be removed because it is either about to be completed, or has already been completed."
          );
        }

        setRemoveBookingId(null);
        setViewBooking(null);
      } catch (e) {
        console.error(e);
      }
      unsetSpinner();
    }
  };

  const combineAddedVehicle = (trips, NewVehicles) => {
    if (NewVehicles) {
      NewVehicles.map((vehicle, index) => {
        if (
          trips.filter(
            ({ shuttle_id, driver_id, driver_team_id }) =>
              shuttle_id === vehicle.shuttle_id &&
              driver_id === vehicle.driver_id &&
              driver_team_id === vehicle.driver_team_id &&
              ((driver_id && vehicle.driver_id) || (driver_team_id && vehicle.driver_team_id))
          ).length === 0 &&
          !addedKeys.includes(index)
        ) {
          trips.push(vehicle);
          addedKeys.push(index);
          setAddedKeys([...addedKeys]);
        }
      });
    } else {
      addedVehicle.map((vehicle, index) => {
        if (
          trips.filter(
            ({ shuttle_id, driver_id, driver_team_id }) =>
              shuttle_id === vehicle.shuttle_id &&
              driver_id === vehicle.driver_id &&
              driver_team_id === vehicle.driver_team_id &&
              ((driver_id && vehicle.driver_id) || (driver_team_id && vehicle.driver_team_id))
          ).length === 0 &&
          !addedKeys.includes(index)
        ) {
          trips.push(vehicle);
          addedKeys.push(index);
          setAddedKeys([...addedKeys]);
        }
      });
    }

    setVehicleTrips([...trips.sort((a, b) => a.shuttle_name.localeCompare(b.shuttle_name))]);
  };

  const handleAddBooking = async (indexKey, bookings) => {
    const elem = vehicleTrips[indexKey];

    if (!elem) {
      return displayNotification('Vehicle not found');
    }

    // check if there are no bookings checked
    let buf = bookings.filter((elem) => elem.checked && !addedBookingIds.includes(elem.id));

    if (!bookings.length || !buf.length) {
      displayNotification('You need to select bookings on the booking list.');
      return;
    }

    buf = buf[0];

    // check if this vehicle already got a driver

    if (!elem.driver_id && !elem.driver_team_id) {
      displayNotification('You need to select a driver on the driver list.');
      return;
    }

    // if vehicle and booking program are not same

    if (buf.program_id !== elem.shuttle_program_id) {
      return displayNotification(
        'The booking can’t be dispatched because the vehicle doesn’t belong to this program anymore.'
      );
    }

    //keep track of whats already added/dispatched
    addedBookingIds.push(buf.id);
    setAddedBookingIds([...addedBookingIds]);

    let checkedBookings = bookings.filter((elem) => elem.checked && !elem.dispatched);

    let uncheckedBookings = bookings.map((elem) => {
      if (elem.checked) return { ...elem, dispatched: true };
      return elem;
    });

    // check validation to support the wheelchair - start
    let supportWheelchair = true;

    checkedBookings.forEach((booking) => {
      const wheelchair = booking.mobility_assistance.find((element) => element?.toLowerCase() == 'wheelchair');
      const stretcher = booking.mobility_assistance.find((element) => element?.toLowerCase() == 'stretcher');

      const wasAssignedVehicleInColumn = vehicleLists.filter((item) => item.shuttle_id == elem.shuttle_id);

      if (wasAssignedVehicleInColumn[0]?.wheelchair == 'none' && (wheelchair != undefined || stretcher != undefined)) {
        supportWheelchair = false;
        return;
      }
    });

    if (!supportWheelchair) {
      this.displayNotification('The vehicle doesn’t support the rider’s mobility aid need.');
      return;
    }
    // check validation to support the wheelchair - end

    setSpinner();

    try {
      const response = await Promise.all(
        checkedBookings.map((booking) => {
          if (booking.ride_status === 0) {
            const param = {
              booking_id: booking.id,
              pickup_time: composePickupTime(booking.pickup_time),
              shuttle_id: elem.shuttle_id,
              driver_id: elem.driver_id,
              driver_team_id: elem.driver_team_id,
              insurance_account_id: elem.insurance_account_id,
              // travel_time: booking.travel_time,
              // pickup_location_id: booking.pickup_address_id,
              // dropoff_location_id: booking.dropoff_address_id,
              // program_id: booking.program_id,
              // number_of_passengers: booking.total_seat_count
            };

            return createNewSingleFlexTrip(param);
          } else if (
            booking.ride_status == 2 ||
            booking.ride_status == 3 ||
            booking.ride_status == 4 ||
            booking.ride_status == 5 ||
            booking.ride_status == 6 ||
            booking.ride_status == 7
          ) {
            displayNotification('The booking can’t be moved because the trip is already completed.');
            return true;
          } else if (booking.ride_status === 1) {
            const param = {
              booking_id: booking.id,
              shuttle_id: elem.shuttle_id,
              driver_id: elem.driver_id,
            };
            return updateDispatchTrip(param);
          } else {
            return true;
          }
        })
      );

      response.forEach((res) => {
        if (res && res.status && res.status === 'success') {
          const { trip } = res.data;

          // the api doesnt return complete data, so we need to do it in the frontEnd
          if (trip.bookings[0] && trip.bookings[0].booking_id) {
            const completeBooking = checkedBookings.filter(
              ({ booking_id }) => booking_id === trip.bookings[0].booking_id
            );
            if (completeBooking.length > 0) {
              trip.bookings[0] = {
                ...trip.bookings[0],
                pickup_city: completeBooking[0].pickup_city,
                dropoff_city: completeBooking[0].dropoff_city,
                insurance_account_id: completeBooking[0].insurance_account_id,
                dropoff_account_id: completeBooking[0].dropoff_account_id,
                pickup_account_id: completeBooking[0].pickup_account_id,
                total_addl_fee_usd_cents: completeBooking[0].total_addl_fee_usd_cents,
              };
            }
          }

          uncheckedBookings = [
            ...uncheckedBookings.map((item) => {
              if (trip.bookings[0] && item.id === trip.bookings[0].booking_id)
                return {
                  ...item,
                  ride_status: trip.bookings[0].ride_status,
                  status: rideStatus(trip.bookings[0]),
                };
              return item;
            }),
          ];

          if (trip && trip.trip_id && trip.bookings[0]) {
            // these values are not being returned by the api so we need to copy it over to the trip list

            trip.bookings[0].scheduled_pickup_time = dayjs(trip.bookings[0].scheduled_pickup_time, 'HH:mm').format(
              'h:mm A'
            );
            trip.bookings[0].scheduled_dropoff_time = dayjs(trip.bookings[0].scheduled_dropoff_time, 'HH:mm').format(
              'h:mm A'
            );
            trip.bookings[0].pickup_time = dayjs(trip.bookings[0].pickup_time, 'HH:mm').format('h:mm A');

            const foundIndex = elem.trips.findIndex(({ trip_id }) => trip_id === trip.trip_id);

            if (elem.trips.length === 0 || foundIndex === -1) {
              elem.trips.push(trip);
            } else {
              elem.trips = [
                ...elem.trips.map((vTrips) => {
                  if (vTrips.trip_id === trip.trip_id) {
                    //check if booking exist
                    const index = vTrips.bookings.findIndex(
                      (booking) => booking.booking_id === trip.bookings[0].booking_id
                    );

                    if (index > -1) {
                      vTrips.bookings[index] = trip.bookings[0];
                    } else {
                      vTrips.bookings.push(trip.bookings[0]);
                    }
                  }
                  return vTrips;
                }),
              ];
            }

            vehicleTrips[indexKey] = elem;

            updateBooking([...uncheckedBookings]);
            combineAddedVehicle([...vehicleTrips]);
          }

          // remove recommended trips
          const removeRecommendationTrips = vehicleTrips[indexKey].trips.filter((trip) => !trip.recommendation);
          vehicleTrips[indexKey].trips = [...removeRecommendationTrips];
          if (removeRecommendationTrips.length > 0) {
            updateVehicle([...vehicleTrips]);
            setVehicleTrips([...vehicleTrips]);
          }
        } else {
          if (res.message.error_code === 'MANUALDISPATCHPROGRAMERROR') {
            displayNotification(
              'The booking can’t be dispatched because the vehicle doesn’t belong to this program anymore.'
            );
          }
        }
      });

      // remove the newly dispatch bookings from the auto schedule trip list
      const bookingIds = checkedBookings.map(({ booking_id }) => booking_id);

      const updatedAutoScheduleData = autoScheduleData.map((trip, key) => {
        if (key !== indexKey) {
          trip.trips = [
            ...trip.trips.filter((booking) => {
              booking.bookings = [...booking.bookings.filter(({ booking_id }) => !bookingIds.includes(booking_id))];

              return booking.bookings.length > 0;
            }),
          ];
        }

        return trip;
      });
      setAutoScheduleData([...updatedAutoScheduleData]);
      updateAutoScheduleData(bookingIds);
    } catch (e) {
      console.error(e);
    }

    unsetSpinner();
  };

  const copyTrackingLink = (value) => {
    navigator.clipboard.writeText(`${TrackingDomain}${showTrackingLink}`);
    displayNotification('Link copied.');
  };

  const composePickupTime = (pickup_time) => {
    const time = new Date(`${selectedDate} ${pickup_time}`);

    return dayjs(`${selectedDate} ${time.getHours()}:${time.getMinutes()}:00`).format('YYYY-MM-DDTHH:mm');
  };

  const updateLunchBreak = async () => {
    if (!timeBlockStart || !timeBlockEnd) {
      displayNotification('Please input a valid time range.');

      return;
    }

    const start = setTimeWithDate(selectedDate, timeBlockStart);
    const end = setTimeWithDate(selectedDate, timeBlockEnd);

    if (dateRangeValid(start, end) === false) {
      displayNotification('Please input a valid time range.');

      return;
    }
    setSpinner();
    try {
      const params = {
        to_delete: false,
        trip_id: viewedBooking.trip.trip_id,
        start_time: dayjs(start).format('YYYY-MM-DDTHH:mm'), // (Format: "2023-03-28T16:00")
        end_time: dayjs(end).format('YYYY-MM-DDTHH:mm'), // (Format: "2023-03-28T16:00")
      };

      const resp = await saveLunchBreak(params);

      if (resp.status === 'success') {
        displayNotification('Successfully updated lunch break.');

        const { trip } = resp.data;
        if (trip.drivershifts?.length === 0 || !trip.dirvershifts) {
          trip.drivershifts = ['exists'];
        }

        const shuttleIndex = vehicleTrips.findIndex(
          ({ shuttle_id, driver_id, driver_team_id }) =>
            shuttle_id === trip.shuttle?.shuttle_id &&
            driver_id === trip.driver_id &&
            driver_team_id === trip.driver_team_id
        );

        if (shuttleIndex > -1) {
          const tripIndex = vehicleTrips[shuttleIndex].trips.findIndex(({ trip_id }) => trip_id === trip.trip_id);

          if (tripIndex > -1) {
            vehicleTrips[shuttleIndex].trips[tripIndex] = trip;
            vehicleTrips[shuttleIndex].trips = [...vehicleTrips[shuttleIndex].trips];
            combineAddedVehicle([...vehicleTrips]);
          }
        }

        setShowLunchBreakModal(false);
        setViewBooking(null);
      } else {
        displayNotification(resp.message.message);
      }
    } catch (e) {
      console.error(e);
    }
    unsetSpinner();
  };

  const updateTimeBlock = async () => {
    if (!timeBlockStart || !timeBlockEnd) {
      displayNotification('Please input a valid time range.');

      return;
    }

    if (!dateRangeValid(timeBlockStart, timeBlockEnd)) {
      displayNotification('Please input a valid time range.');
      return;
    }

    setSpinner();
    const start = setTimeWithDate(selectedDate, timeBlockStart);
    const end = setTimeWithDate(selectedDate, timeBlockEnd);

    try {
      const params = {
        to_delete: false,
        trip_id: viewedBooking.trip.trip_id,
        start_time: dayjs(start).format('YYYY-MM-DDTHH:mm'), // (Format: "2023-03-28T16:00")
        end_time: dayjs(end).format('YYYY-MM-DDTHH:mm'), // (Format: "2023-03-28T16:00")
        notes: timeBlockNotes,
      };

      const resp = await patchTimeBlock(params);

      if (resp.status === 'success') {
        displayNotification('Successfully updated time block.');

        const trip = resp.data;

        const shuttleIndex = vehicleTrips.findIndex(
          ({ shuttle_id, driver_id, driver_team_id }) =>
            shuttle_id === trip.shuttle?.shuttle_id &&
            driver_id === trip.driver_id &&
            driver_team_id === trip.driver_team_id
        );

        if (shuttleIndex > -1) {
          const tripIndex = vehicleTrips[shuttleIndex].trips.findIndex(({ trip_id }) => trip_id === trip.trip_id);

          if (tripIndex > -1) {
            vehicleTrips[shuttleIndex].trips[tripIndex] = trip;
            vehicleTrips[shuttleIndex].trips = [...vehicleTrips[shuttleIndex].trips];
            combineAddedVehicle([...vehicleTrips]);
          }
        }

        setShowTimeBlockModal(false);
        setViewBooking(null);
      } else {
        displayNotification(resp.message);
      }
    } catch (e) {
      console.error(e);
    }
    unsetSpinner();
  };

  const deleteDriverAction = async (booking) => {
    setSpinner();
    try {
      const { trip } = booking;
      const params = {
        trip_id: trip.trip_id,
        to_delete: true,
      };
      const resp = await saveDriverAction(params);

      if (resp.status === 'success') {
        const shuttleId = trip.shuttle?.shuttle_id ? trip.shuttle?.shuttle_id : booking.shuttle_id;
        const shuttleIndex = vehicleTrips.findIndex(
          ({ shuttle_id, driver_id, driver_team_id }) =>
            shuttle_id === shuttleId && driver_id === trip.driver_id && driver_team_id === trip.driver_team_id
        );

        if (shuttleIndex > -1) {
          const tripIndex = vehicleTrips[shuttleIndex].trips.findIndex(({ trip_id }) => trip_id === trip.trip_id);

          if (tripIndex > -1) {
            vehicleTrips[shuttleIndex].trips.splice(tripIndex, 1);

            combineAddedVehicle([...vehicleTrips]);
          }
        }

        setViewBooking(null);
        displayNotification('Successfully deleted Driver action.');
      } else {
        displayNotification(resp.message.message);
      }
    } catch (e) {
      console.error(e);
    }
    unsetSpinner();
  };

  const deleteLunchBreak = async (booking) => {
    setSpinner();
    const { trip } = booking;
    try {
      const params = {
        trip_id: trip?.trip_id,
        to_delete: true,
        start_time: timeBlockStart,
        end_time: timeBlockEnd,
      };

      const shuttleId = trip.shuttle?.shuttle_id ? trip.shuttle?.shuttle_id : booking.shuttle_id;

      const resp = await saveLunchBreak(params);
      if (resp.status === 'success') {
        const shuttleIndex = vehicleTrips.findIndex(
          ({ shuttle_id, driver_id, driver_team_id }) =>
            shuttle_id === shuttleId && driver_id === trip.driver_id && driver_team_id === trip.driver_team_id
        );

        if (shuttleIndex > -1) {
          const tripIndex = vehicleTrips[shuttleIndex].trips.findIndex(({ trip_id }) => trip_id === trip.trip_id);

          if (tripIndex > -1) {
            vehicleTrips[shuttleIndex].trips.splice(tripIndex, 1);

            combineAddedVehicle([...vehicleTrips]);
          }
        }

        setViewBooking(null);
        displayNotification('Successfully deleted lunch break.');
      } else {
        displayNotification(resp.message);
      }
    } catch (e) {
      console.error(e);
    }

    unsetSpinner();
  };

  const deleteTimeBlock = async (booking) => {
    setSpinner();
    const { trip } = booking;
    try {
      const params = {
        trip_id: trip?.trip_id,
        to_delete: true,
      };

      const shuttleId = trip.shuttle?.shuttle_id ? trip.shuttle?.shuttle_id : booking.shuttle_id;

      const resp = await patchTimeBlock(params);
      if (resp.status === 'success') {
        const shuttleIndex = vehicleTrips.findIndex(
          ({ shuttle_id, driver_id, driver_team_id }) =>
            shuttle_id === shuttleId && driver_id === trip.driver_id && driver_team_id === trip.driver_team_id
        );

        if (shuttleIndex > -1) {
          const tripIndex = vehicleTrips[shuttleIndex].trips.findIndex(({ trip_id }) => trip_id === trip.trip_id);

          if (tripIndex > -1) {
            vehicleTrips[shuttleIndex].trips.splice(tripIndex, 1);

            combineAddedVehicle([...vehicleTrips]);
          }
        }

        setViewBooking(null);
        displayNotification('Successfully deleted time block.');
      } else {
        displayNotification(resp.message);
      }
    } catch (e) {
      console.error(e);
    }

    unsetSpinner();
  };

  const handleMouseLeave = (id) => {
    const divContainer = document.getElementById(id);
    if (divContainer) {
      divContainer.style.display = 'none';
    }
  };

  const handleHover = (id) => {
    if (dashBoardWidth) {
      const cont = document.getElementById('dispatch-board');
      const containerDiv = document.getElementById(id);

      if (containerDiv) {
        containerDiv.style.display = 'block';
      }
      if (containerDiv && cont.scrollWidth > dashBoardWidth) {
        containerDiv.style.left = '-120px';
        // setTimeout(() => {
        //   cont.scrollTo({
        //     left: cont.scrollWidth,
        //     top: cont.scrollTop,
        //     behavior: "smooth"
        //   });
        // }, 600)
      }
    }
  };

  const handleTripMenuToggle = (e, index) => {
    setXYPosition({
      x: e.clientX,
      y: e.clientY,
    });
    const temp = [...showTripMenu.map(() => false)];
    temp[index] = true;
    setShowTripMenu([...temp]);
  };

  useEffect(() => {
    const timezone = selectedProgram?.program_timezone;
    const currentDate = dayjs()
      .tz(timezone)
      .startOf('day');
    const parsedSelectedDate = dayjs.tz(selectedDate, timezone).startOf('day');

    // do not show shuttle for past and future trips
    if (currentDate.isSame(parsedSelectedDate, 'day')) {
      if (isShowDriverRouteCompare && !isEmpty(vehicleTrips)) {
        const shuttleIds = vehicleTrips.map(({ shuttle_id }) => shuttle_id);
        const shuttleRef = collection(firestore, 'ShuttleGeolocation');
        setShuttleQuery(query(shuttleRef, where('shuttle_id', 'in', shuttleIds)));
      } else if (!isShowDriverRouteCompare && !isEmpty(viewedVehicleTrip)) {
        const shuttleId = viewedVehicleTrip.shuttle_id;
        const shuttleRef = collection(firestore, 'ShuttleGeolocation');
        setShuttleQuery(query(shuttleRef, where('shuttle_id', 'in', [shuttleId])));
      }
    }
  }, [vehicleTrips, viewedVehicleTrip, isShowDriverRouteCompare]);

  const handleSetViewBooking = (booking, index) => {
    if (vehicleTrips[index]) {
      setViewedVehicleTrip({ ...vehicleTrips[index], index });
      setCurrentViewedCar(vehicleTrips[index].shuttle_name);
    } else if (booking.type === 'autoScheduling') {
      // setViewedVehicleTrip({ ...autoScheduleData[index], index });
      setCurrentViewedCar(booking.shuttleName);
    }
    setViewBooking(booking);
  };

  const [shuttleDoc, , shuttleError] = useCollection(shuttleQuery);
  const shuttles = shuttleDoc && !shuttleError ? shuttleDoc.docs : [];

  // this is a temporary fix due to release deadline, refactor this later
  let tripsData = vehicleTrips.filter(function(objFromA) {
    return !autoScheduleData.find(function(objFromB) {
      return (
        objFromB.driver_id === objFromA.driver_id &&
        objFromB.driver_team_id === objFromA.driver_team_id &&
        objFromB.shuttle_id === objFromA.shuttle_id
      );
    });
  });

  tripsData = [
    ...tripsData.filter((obj, index) => {
      return (
        index ===
        tripsData.findIndex(
          (o) =>
            obj.shuttle_id === o.shuttle_id && obj.driver_id === o.driver_id && obj.driver_team_id === o.driver_team_id
        )
      );
    }),
  ];

  return (
    <div className="App" id="dispatch-board">
      {isShowHeatMapMap && (
        <HeatMapInfo hideHeatMapMap={hideHeatMapMap} heatMap={heatMap[(selectedProgram?.program_id)] || []} />
      )}

      {showTrackingLink && (
        <div className="tracking__list_modal">
          <div className="traciking__list_madal_content">
            <div className="text-center py-3">
              <img src={`${process.env.PUBLIC_URL}/resources/images/icon_info_blue.png`} alt="" />
            </div>
            <div className="py-3">
              Copy the vehicle tracking url below, and share it via email or text message so that riders or other users
              can track their vehicles on their own.
            </div>
            <div className="py-1 url" onClick={() => copyTrackingLink(`${TrackingDomain}${showTrackingLink}`)}>
              <span>{`${TrackingDomain}${showTrackingLink}`}</span>

              <div className="copy-button">
                <div
                  onClick={() => setShowTrackingLink(null)}
                  variant="secondary"
                  style={{ marginLeft: 10, fill: '#118bf0' }}
                  className="fa fa-copy"
                />{' '}
                <span>&nbsp;Copy url</span>
              </div>
            </div>
            <div className="py-3">
              <strong className="text-bold">Note:</strong>&nbsp; Tracking starts 30 minutes before scheduled pickup
              time, and ends once the trip is completed or the trip date ends.
            </div>
            <div className="text-right py-lg-3 py-sm-3 py-md-3">
              <Button
                onClick={() => setShowTrackingLink(null)}
                variant="secondary"
                style={{ marginLeft: 10 }}
                className="traciking__list_madal_button"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}

      {isFetchingList ? (
        <div className="main">
          <LoaderVersionOne message="Thanks for waiting, we are setting up your trip list." />
          {hours.map((hour, index) => {
            return (
              <HourRow
                isNewScheduler={isNewScheduler}
                showHeatMap={showHeatMap}
                key={index}
                parentIndex={index}
                searchValue={searchValue}
                hour={hour}
                setViewBookingParent={() => {}}
                vehicleTrips={[]}
                setSpinner={setSpinner}
                unsetSpinner={unsetSpinner}
                selectedDate={selectedDate}
                setVehicleTrips={setVehicleTrips}
                heatMap={heatMap}
              />
            );
          })}
        </div>
      ) : (
        <div className="main">
          {(isShowDriverRouteCompare || isShowRouteCompare) && (
            <FleetRouteCompare
              shuttles={shuttles}
              composePickupTime={composePickupTime}
              isDriverRoute={isShowDriverRouteCompare}
              bookings={bookings}
              tripsOfEachVehicle={tripsOfEachVehicle}
              vehicleTrips={tripsData}
              viewedVehicleTrip={viewedVehicleTrip}
              viewedBooking={isShowDriverRouteCompare ? preSelectedBooking : viewedBooking}
              isMobileView={isMobileView}
              handleAddBooking={handleAddBooking}
              close={() => {
                if (isShowDriverRouteCompare) {
                  setShowDriverRouteCompare(false);
                } else {
                  setIsShowRouteCompare(false);
                  setViewedVehicleTrip({});
                }
              }}
            />
          )}

          {showUpdateBookingTripTime && (
            <UpdateBookingTripTime
              viewedBooking={viewedBooking}
              closeModal={() => setShowUpdateBookingTripTime(false)}
              updateBooking={handleUpdateBooking}
              updateAdminNote={handleUpdateAdminNote}
              availableTBookingTime={availableTBookingTime}
            />
          )}

          {removeBookingId && (
            <ConfirmationModal
              removeDispatchedBooking={removeDispatchedBooking}
              setRemoveBookingId={setRemoveBookingId}
            />
          )}

          {showDriverAction && (
            <DriverAction
              show={showDriverAction}
              cancel={() => setShowDriverAction(false)}
              close={() => {
                setShowDriverAction(false);
                setViewBooking(null);
              }}
              dispatchDate={selectedDate}
              tripId={viewedBooking?.trip?.trip_id}
              driverId={viewedBooking?.trip?.driver_id}
              driverTeamId={viewedBooking?.trip?.driver_team_id}
              shuttleId={viewedBooking?.shuttle_id}
              programId={viewedBooking?.trip?.program_id}
              booking={viewedBooking}
              combineAddedVehicle={combineAddedVehicle}
              vehicleTrips={tripsData}
              setSpinner={setSpinner}
              unsetSpinner={unsetSpinner}
            />
          )}

          <LunchBreakModal
            show={showLunchBreakModal}
            setShowModal={setShowLunchBreakModal}
            save={updateLunchBreak}
            setStart={setTimeBlockStart}
            setEnd={setTimeBlockEnd}
            start={timeBlockStart}
            end={timeBlockEnd}
            dispatchDate={selectedDate}
            viewedBooking={viewedBooking}
          />

          {
            // Time block modal

            <TimeBlockModal
              showTimeBlockModal={showTimeBlockModal}
              setShowTimeBlockModal={setShowTimeBlockModal}
              handleSaveTimeBlock={updateTimeBlock}
              setTimeBlockStart={setTimeBlockStart}
              setTimeBlockEnd={setTimeBlockEnd}
              setTimeBlockNotes={setTimeBlockNotes}
              timeBlockNotes={timeBlockNotes}
              timeBlockStart={timeBlockStart}
              timeBlockEnd={timeBlockEnd}
              dispatchDate={selectedDate}
              viewedBooking={viewedBooking}
            />

            // End time block
          }

          {!showLunchBreakModal &&
          !showDriverAction &&
          !showTimeBlockModal &&
          !showUpdateBookingTripTime &&
          viewedBooking &&
          !showTrackingLink &&
          !isNewScheduler ? (
            <>
              {viewedBooking.blockType === 'timeBlock' && (
                <TimeBlockDetails
                  booking={viewedBooking}
                  close={() => setViewBooking(null)}
                  deleteTimeBlock={deleteTimeBlock}
                  updateTimeBlock={() => setShowTimeBlockModal(true)}
                  selectedDate={selectedDate}
                />
              )}
              {viewedBooking.blockType === 'lunchBreak' && (
                <LunchBreakDetails
                  booking={viewedBooking}
                  close={() => setViewBooking(null)}
                  updateLunchBreak={() => setShowLunchBreakModal(true)}
                  deleteLunchBreak={deleteLunchBreak}
                  selectedDate={selectedDate}
                />
              )}
              {viewedBooking.blockType === 'trip' && (
                <BookingDetails
                  showRoutes={setIsShowRouteCompare}
                  getAvailableBookingTime={handleGetAvailableBookingTime}
                  rideStatus={rideStatus}
                  removeBooking={setRemoveBookingId}
                  carName={currentViewedCar}
                  booking={viewedBooking}
                  selectedDate={selectedDate}
                  close={() => setViewBooking(null)}
                  showTrackingLink={setShowTrackingLink}
                />
              )}
              {viewedBooking.blockType === 'driverAction' && (
                <DriverActionDetails
                  booking={viewedBooking}
                  close={() => setViewBooking(null)}
                  updateDriverAction={() => setShowDriverAction(true)}
                  deleteDriverAction={deleteDriverAction}
                  selectedDate={selectedDate}
                />
              )}
            </>
          ) : null}

          {isDownloadTripPdf && (
            <div style={{ position: 'absolute', zIndex: '-1' }}>
              <PdfTemplate
                setIsDownloadTripPdf={setIsDownloadTripPdf}
                displayNotification={displayNotification}
                driverObj={TripPdfDetails.driver}
                tripList={TripPdfDetails.tripList}
                shuttleObj={TripPdfDetails.shuttle}
                date={dayjs(selectedDate).format('dddd, MMMM D, YYYY')}
              />
            </div>
          )}

          <div className="hours-background" />
          <div className="hours__sticky">
            <div className="hours__item hours__item-titles">
              <div className="item-header" />

              {tripsData.map((unsorteddriver, index) => {
                // convert values for pdf
                const driver = unsorteddriver;

                driver.trips.sort((a, b) => a.first_pickup_time.localeCompare(b.first_pickup_time));

                const tripList = driver.trips.map((trip) => {
                  return {
                    ...driver,
                    trip,
                  };
                });

                driver.name = `${driver.driver_firstname} ${driver.driver_lastname}`;

                const shuttle = {
                  make: driver.shuttle_make,
                  model: driver.shuttle_model,
                  license_plate: driver.shuttle_license_plate,
                };

                return (
                  <React.Fragment key={index}>
                    {!isNewScheduler ? (
                      <div id={`vehicle-driver-header-${index}`} key={`item-header-${index}`} className="item-header">
                        <span>{index + 1}</span>
                        <span>
                          {driver.name !== 'undefined undefined' && !usedForTemplate && !isNewScheduler && (
                            <i
                              onClick={() => {
                                setTripPDfDetails({
                                  shuttle,
                                  driver,
                                  tripList,
                                });
                              }}
                              className="fa fa-file-pdf-o pl-1"
                              style={{ color: '#4c5667', cursor: 'pointer' }}
                            />
                          )}
                        </span>

                        <DriverTeamName
                          driver={driver}
                          handleMouseLeave={handleMouseLeave}
                          handleHover={handleHover}
                          updatedAddedVehicles={updatedAddedVehicles}
                          onClickDriverTxt={onClickDriverTxt}
                          index={index}
                        />

                        <div key={index} className="add-driver" onClick={() => handleAddBooking(index, bookings)}>
                          +Bookings
                        </div>

                        <div key={index + 1} style={{ textAlign: 'right' }}>
                          <i
                            className="fa fa-ellipsis-v"
                            onClick={(e) => handleTripMenuToggle(e, index)}
                            style={{ padding: '0 10px' }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div id={`vehicle-driver-header-${index}`} key={`item-header-${index}`} className="item-header">
                        <span>{index + 1}</span>
                        <DriverTeamName
                          driver={driver}
                          handleMouseLeave={handleMouseLeave}
                          handleHover={handleHover}
                          updatedAddedVehicles={updatedAddedVehicles}
                          onClickDriverTxt={onClickDriverTxt}
                          index={index}
                        />
                      </div>
                    )}
                  </React.Fragment>
                );
              })}

              {
                // this will display autoscheduling data
              }
              {autoScheduleData.map((unsorteddriver, index) => {
                // convert values for pdf
                const driver = unsorteddriver;

                driver.trips.sort((a, b) => a.first_pickup_time.localeCompare(b.first_pickup_time));

                driver.name = `${driver.driver_firstname} ${driver.driver_lastname}`;

                return (
                  <React.Fragment key={index}>
                    <div id={`auto-scheduling-data-${index}`} key={`item-header-${index}`} className="item-header">
                      <span>{index + 1 + tripsData.length}</span>
                      <span>&nbsp;&nbsp;&nbsp;</span>
                      <div className="update-shuttle" onClick={() => updatedAddedVehicles(driver, true, index, true)}>
                        {driver.shuttle_name}
                      </div>
                      {driver.driver_firstname || driver.driver_lastname ? (
                        <div
                          className="update-driver"
                          onClick={() => onClickDriverTxt(driver.shuttle_id, index, false, true)}
                        >
                          {`${driver.driver_firstname} ${driver.driver_lastname}`}
                        </div>
                      ) : driver.driver_team_id && driver.driver_team_name ? (
                        <div className="driver-team-title-container">
                          <div
                            className="driver-team-title add-driver"
                            onClick={() => onClickDriverTxt(driver.shuttle_id, index, true, true)}
                            onMouseEnter={() => handleHover(driver.driver_team_name)}
                          >
                            {driver.driver_team_name}
                          </div>
                          <DriverTeamListView drivers={driver} driverTeam={driver.driver_team_name} />
                        </div>
                      ) : (
                        <div
                          className="add-driver"
                          onClick={() => onClickDriverTxt(driver.shuttle_id, index, false, true)}
                        >
                          +Driver
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>

          <div className="hours hour__time-and-driver">
            <div className="hours__item ">
              <div className="item-header" />
              {/** show the current time now in the table */}
              {isShowTimeNow && !usedForTemplate && (
                <TimeNow setRef={setTimeLineNow} timeNow={timeNowLine} selectedProgram={selectedProgram} />
              )}

              {tripsData.map((driver, index) => {
                const driverSchedule = [];

                driverSchedules.forEach((d) => {
                  if (driver.driver_id && driver.driver_id === d.driver_id) {
                    driverSchedule.push(d);
                  } else if (driver.driver_team_id) {
                    // combine all driver schedule'

                    driver.driver_team.forEach((subD) => {
                      // find the driver on driver shift array
                      const driverIndex = driverSchedules.findIndex((ind) => ind.driver_id === subD.driver_id);

                      const indexNotAdded = driverSchedule.findIndex((dri) => dri.driver_id === subD.driver_id);

                      if (driverIndex === -1 && indexNotAdded === -1) {
                        const tempData = {
                          noSched: true,
                          driver_id: subD.driver_id,
                          driver_total_hours: 0,
                          firstname: subD.driver_firstname,
                          lastname: subD.driver_lastname,
                          schedule_data: [],
                        };

                        driverSchedule.push(tempData);
                      }

                      // check if not yet added

                      if (d.driver_id === subD.driver_id && indexNotAdded === -1) {
                        d.noSched = false;
                        driverSchedule.push(d);
                      }
                    });
                  }
                });

                return (
                  <React.Fragment key={index}>
                    <div
                      key={`item-header-${index}-${driver.driver_id}-${driver.driver_team_id}`}
                      className="item-header"
                    >
                      {!usedForTemplate && (driver.driver_id || driver.driver_team_id) && (
                        <DriversWorkingHours
                          bookings={bookings}
                          isNewScheduler={isNewScheduler}
                          refreshed={getFreshData}
                          xyPositionProps={xyPosition}
                          showTripMenu={showTripMenu[index]}
                          isTeam={
                            driver.driver_team_id && driver.driver_team_id !== null && driver.driver_team_id !== ''
                          }
                          combineAddedVehicle={combineAddedVehicle}
                          vehicleTrips={tripsData}
                          selectedDate={selectedDate}
                          disPatchSelectedDate={selectedDate}
                          driver={driver}
                          scheduleId={scheduleId}
                          trips={[...driver.trips] || []}
                          hourHeightInPixel={hourHeightInPixel}
                          converterToHeight={converterToHeight}
                          driverSchedule={driverSchedule}
                          key={`driver-working-hours-${index}-${driver.driver_team_id}-${driver.driver_id}`}
                          allDriverList={driverSchedules}
                          programId={programId}
                          setSpinner={setSpinner}
                          unsetSpinner={unsetSpinner}
                          updateDriverInAllDriversArray={updateDriverInAllDriversArray}
                        />
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>

          {hours.map((hour, index) => {
            return (
              <HourRow
                isNewScheduler={isNewScheduler}
                key={index}
                showHeatMap={showHeatMap}
                parentIndex={index}
                searchValue={searchValue}
                hour={hour}
                bookings={bookings}
                updateBooking={updateBooking}
                setViewBookingParent={handleSetViewBooking}
                vehicleTrips={tripsData.concat(autoScheduleData)}
                setSpinner={setSpinner}
                unsetSpinner={unsetSpinner}
                selectedDate={selectedDate}
                setVehicleTrips={setVehicleTrips}
                heatMap={heatMap}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const TimeNow = ({ setRef, timeNow, selectedProgram }) => {
  const [top, setTop] = useState(0);
  useEffect(() => {
    computeTop();
  }, []);

  const computeTop = () => {
    clearTimeout(computeTop);
    let now = new Date();
    if (selectedProgram && selectedProgram.program_timezone) {
      now = new Date(
        new Date().toLocaleString('en', {
          timeZone: selectedProgram.program_timezone,
        })
      );
    }

    setTop(now.getHours() * hourHeightInPixel + now.getMinutes() * converterToHeight);

    // this is for the realtime effect where the line moves every 1 min
    setTimeout(() => {
      computeTop();
    }, 60000);
  };

  return (
    <div
      ref={(node) => {
        if (node && !timeNow) {
          setRef(node);
        }
      }}
      className="time-now"
      style={{
        top: `${top}px`,
      }}
    />
  );
};

const HourRow = ({
  isNewScheduler,
  parentIndex,
  hour,
  vehicleTrips,
  setSpinner,
  unsetSpinner,
  selectedDate,
  searchValue,
  setViewBookingParent,
  setVehicleTrips,
  heatMap,
  showHeatMap,
  bookings,
  updateBooking,
}) => {
  const { isSwipe } = useContext(ToggleSwipeContext);

  useLayoutEffect(() => {
    bookingOverlayCheck();
  }, [hour, vehicleTrips, selectedDate]);

  const [isShowMore, setIsShowMore] = useState();
  const [startPos, setStartPros] = useState();
  const [{ dx, dy }, setOffset] = React.useState({
    dx: 0,
    dy: 0,
  });
  const [isMobile, setIsMobile] = useState(false);

  const eleRef = React.useRef([]);

  /**
   * this will copy the element/booking and use that copy to display a temporary
   * booking element everytime the dragged element/booking enters a column on each hour row
   * @param {*} e event (the area in hour row) where the element/booking entered on drag
   */
  const handleOnDragEnter = (e) => {
    e.preventDefault();
    let data = JSON.parse(manageLocalStorage('draggedItem', 'get'));

    if (e.target.className === 'item' && data) {
      const bookingDiv = document.getElementById(data.booking_id);
      if (bookingDiv) {
        /**
         * clone the booking div which is the one being dragged
         * and set its z-index to -negative to send it way back
         * cause we only need it for a background element
         */
        const clone = bookingDiv.cloneNode(true);
        clone.id = 'clone';
        clone.style.opacity = '1';
        clone.style.overflow = 'hidden';
        clone.style['z-index'] = '-100';
        clone.draggable = false;
        clone.onDragEnd = null;
        clone.onDragStart = null;
        clone.style.position = 'absolute';

        e.target.appendChild(clone);
        /**
         * user the cloned element as background of the column
         * where the booking div is being dragged on
         */
        // this will create the backgroud container

        if (getHourFromString(hour) === getHourFromString(data.scheduled_pickup_time)) {
          e.target.style[
            'background-image'
          ] = `url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" id="svgTag"><rect x="0" y="0" width="${hourHeightInPixel}" height="${
            clone.style.height.split('px')[0]
          }" fill="rgb(235, 123, 123)"></rect><text x="0" y="15" fill="white" font-size="12px" font-family="Arial, Helvetica, sans-serif">${
            data.passenger_firstname
          } ${
            data.passenger_lastname
          }</text><text x="0" y="30" fill="white" font-size="12px" font-family="Arial, Helvetica, sans-serif">${
            data.scheduled_pickup_time
          } - ${data.scheduled_dropoff_time}</text></svg>')`;
        }

        e.target.style['background-position'] = `0 ${clone.style.top}`;
        e.target.style['background-repeat'] = 'no-repeat';
        e.target.style['background-color'] = '#ffffff';
      }
    }
  };

  /**
   * This will reset the column background on dragged event
   * This will also delete the cloned booking div
   * @param {*} e event (where the booking div) drag event left;
   */
  const handleOnDragLeave = (e) => {
    e.preventDefault();
    if (e.target.className === 'item') {
      /**
       * delete the cloned booking element
       */
      removeCloneDiv();

      showHiddenBooking(e?.target);

      e.target.style['background-image'] = 'none';
      e.target.style['background'] = itemBackground;
    }
  };

  const composePickupTime = (pickup_time) => {
    return dayjs(`${selectedDate} ${pickup_time}:00`).format('YYYY-MM-DDTHH:mm');
  };

  /*
   * THis will validate the drop area
   * making sure that driver/team and shuttle is already set
   */
  const validateDropArea = (e, bookingsOnDropArea) => {
    if (bookingsOnDropArea && !bookingsOnDropArea.driver_id && !bookingsOnDropArea.team_id) {
      if (e) {
        e.target.style['background'] = itemBackground;
        showHiddenBooking(e?.target);
      }

      displayNotification('You need to add a driver.');
      return false;
    }

    if (bookingsOnDropArea && !bookingsOnDropArea.shuttle_id) {
      if (e) {
        e.target.style['background'] = itemBackground;
        showHiddenBooking(e?.target);
      }
      displayNotification('You need to add a shuttle.');
      return false;
    }

    if (bookingsOnDropArea.driver_id && bookingsOnDropArea.team_id) {
      if (e) {
        e.target.style['background'] = itemBackground;
        showHiddenBooking(e?.target);
      }
      displayNotification('Booking driver Id and team Id conflict.');
      return false;
    }

    return true;
  };

  /**
   * This will move the dragged booking div permanently
   * @param {*} e event (where the booking)
   */
  const handleDrop = (e) => {
    e.preventDefault();

    const bookingsOnDropArea = JSON.parse(e.target.getAttribute('data'));

    /**
     * This is the data/info about the booking being dragged
     */
    const data = JSON.parse(manageLocalStorage('draggedItem', 'get'));

    if (bookingsOnDropArea && data.program_id !== bookingsOnDropArea.shuttle_program_id) {
      e.target.style['background'] = itemBackground;
      removeCloneDiv();
      showHiddenBooking(e?.target);
      manageLocalStorage('draggedItem', 'remove');
      displayNotification(
        'The booking can’t be dispatched because the vehicle doesn’t belong to this program anymore.'
      );
      return true;
    }

    if (data.type === 'autoScheduling') {
      // call create new flex trip api, means moving a trip from auto schedule data to an existing trips column

      if (
        bookingsOnDropArea.type !== 'autoScheduling' ||
        (bookingsOnDropArea.shuttle_id && bookingsOnDropArea.driver_id) ||
        (bookingsOnDropArea.shuttle_id && bookingsOnDropArea.driver_team_id)
      ) {
        singleFlexTrip(bookingsOnDropArea, data);
      } else {
        updateAutoScheduleTrip(bookingsOnDropArea, data);
      }

      showHiddenBooking(e?.target);
      e.target.style['background'] = itemBackground;
      removeCloneDiv();
      return true;
    }

    if (e.target.className === 'item' && data && validateDropArea(e, bookingsOnDropArea)) {
      // remove temporary trip displayed
      e.target.style['background'] = itemBackground;
      removeCloneDiv();

      const params = {
        bookings: [data.booking_id],
        driver_id: bookingsOnDropArea.driver_id,
        team_id: bookingsOnDropArea.team_id,
        shuttle_id: bookingsOnDropArea.shuttle_id,
      };

      /**
       * Do an api call if a booking was moved on drop
       * keep logic just incase
       */
      updateBookingTrip(params, data, bookingsOnDropArea);
      // }

      showHiddenBooking(e?.target);
    }
    manageLocalStorage('draggedItem', 'remove');
  };

  const singleFlexTrip = async (dropAreaData, data) => {
    setSpinner();
    try {
      const param = {
        booking_id: data.booking_id,
        pickup_time: composePickupTime(data.pickup_time),
        shuttle_id: dropAreaData.shuttle_id,
        driver_id: dropAreaData.driver_id,
        driver_team_id: dropAreaData.team_id,
      };

      const resp = await createNewSingleFlexTrip(param);

      if (resp.status === 'success') {
        // insert this trip
        const destinationIndex = vehicleTrips.findIndex(
          (trip) =>
            trip.shuttle_id === dropAreaData.shuttle_id &&
            trip.driver_id === dropAreaData.driver_id &&
            trip.driver_team_id === dropAreaData.team_id
        );

        const sourceIndex = JSON.parse(manageLocalStorage('draggedItemTripIndex', 'get'));

        const sourceTripIndex = vehicleTrips[sourceIndex].trips.findIndex(
          (trip) => trip.bookings[0].booking_id === data.booking_id
        );

        if (destinationIndex > -1 && sourceTripIndex > -1) {
          const newTrip = vehicleTrips[sourceIndex].trips[sourceTripIndex];
          if (newTrip.bookings.length > 0) {
            newTrip.bookings = [
              ...newTrip.bookings.map((booking) => {
                delete booking.type;

                booking.ride_status = 1;
                booking.status = 'Dispatched';

                booking.trip_id = resp.data?.trip?.trip_id
                  ? resp.data.trip.trip_id
                  : vehicleTrips[sourceIndex].trips[sourceTripIndex].trip_id;

                // update booking list
                const bookingIndex = bookings.findIndex(({ booking_id }) => booking_id === booking.booking_id);

                if (bookingIndex > -1) {
                  bookings[bookingIndex].ride_status = 1;
                  bookings[bookingIndex].status = 'Dispatched';
                  bookings[bookingIndex].trip_id = vehicleTrips[sourceIndex].trips[sourceTripIndex].trip_id;

                  bookings = [...bookings];
                }

                return booking;
              }),
            ];
          }

          // update the booking list
          updateBooking([...bookings]);

          vehicleTrips[sourceIndex].trips.splice(sourceTripIndex, 1);
          vehicleTrips[sourceIndex].trips = [...vehicleTrips[sourceIndex].trips];
          vehicleTrips[destinationIndex].trips.push(newTrip);
          vehicleTrips[destinationIndex].trips = [...vehicleTrips[destinationIndex].trips];

          setVehicleTrips([...vehicleTrips]);
        }
      } else {
        displayNotification('Failed to move the trip. Please try again later.');
      }
    } catch (e) {
      console.error(e);
    }
    unsetSpinner();
  };

  // move auto schedule trip from one auto schedule trip column to another auto schedule trip column
  const updateAutoScheduleTrip = (dropAreaData, data) => {
    const shuttleName = dropAreaData.shuttle_name;
    if (shuttleName) {
      const sourceIndex = JSON.parse(manageLocalStorage('draggedItemTripIndex', 'get'));

      const destinationIndex = vehicleTrips.findIndex((trip) => trip.shuttle_name === shuttleName);

      const sourceTripIndex = vehicleTrips[sourceIndex].trips.findIndex(
        (trip) => trip.bookings[0].booking_id === data.booking_id
      );
      if (destinationIndex > -1 && sourceTripIndex > -1) {
        const newTrip = vehicleTrips[sourceIndex].trips[sourceTripIndex];
        vehicleTrips[sourceIndex].trips.splice(sourceTripIndex, 1);
        vehicleTrips[sourceIndex].trips = [...vehicleTrips[sourceIndex].trips];
        vehicleTrips[destinationIndex].trips.push(newTrip);
        vehicleTrips[destinationIndex].trips = [...vehicleTrips[destinationIndex].trips];
        setVehicleTrips([...vehicleTrips]);
      }
    }
  };

  const updateBookingTrip = async (params, data, bookingsOnDropArea) => {
    try {
      setSpinner();
      const result = await updateDispatchTrip(params);
      if (result.status === 'success') {
        const sourceIndex = JSON.parse(manageLocalStorage('draggedItemTripIndex', 'get'));

        const destinationIndex = vehicleTrips.findIndex(
          (trip) =>
            trip.shuttle_id === bookingsOnDropArea.shuttle_id &&
            trip.driver_id === bookingsOnDropArea.driver_id &&
            trip.driver_team_id === bookingsOnDropArea.team_id
        );

        let sourceTripIndex = -1;

        if (data.trip_id === null) {
          sourceTripIndex = vehicleTrips[sourceIndex].trips.findIndex(
            (trip) => trip.bookings.findIndex(({ booking_id }) => booking_id === data.booking_id) > -1
          );
        } else {
          sourceTripIndex = vehicleTrips[sourceIndex].trips.findIndex((trip) => trip.trip_id === data.trip_id);
        }

        // if not yet found
        // dragg trip is from auto schedule
        // if so trip_id is inside bookings array
        if (sourceTripIndex === -1) {
          sourceTripIndex = vehicleTrips[sourceIndex].trips.findIndex(
            (trip) => trip.bookings[0]?.trip_id === data.trip_id
          );
        }

        if (destinationIndex > -1 && sourceTripIndex > -1) {
          const newTrip = vehicleTrips[sourceIndex].trips[sourceTripIndex];

          vehicleTrips[sourceIndex].trips.splice(sourceTripIndex, 1);
          vehicleTrips[sourceIndex].trips = [...vehicleTrips[sourceIndex].trips];
          vehicleTrips[destinationIndex].trips.push(newTrip);
          vehicleTrips[destinationIndex].trips = [...vehicleTrips[destinationIndex].trips];

          setVehicleTrips([...vehicleTrips]);
        }
      }
    } catch (e) {
      console.error(e);
    }

    unsetSpinner();
  };

  /**
   * Hide booking when another booking is being dragover it
   */
  const handleHideBooking = (e) => {
    let data = JSON.parse(manageLocalStorage('draggedItem', 'get'));
    if (e.target.id && e.target.id !== data.booking_id) {
      e.target.style.visibility = 'hidden';
    }
  };

  const showHiddenBooking = (element) => {
    if (element) {
      // update all hidden children
      const childrenNodes = element.childNodes;

      childrenNodes.forEach((child) => (child.style.visibility = 'visible'));
      document.querySelectorAll('.trip-box-container').forEach((e) => (e.style.visibility = 'visible'));
    }
  };

  /**
   * This will check all booking if there are any overlay
   * between each other, it will resize those booking
   * so that it will be visible to users
   */
  const bookingOverlayCheck = () => {
    vehicleTrips.forEach((v, index) => {
      const points = document.querySelectorAll(
        `.box-${index}-${hour
          .toString()
          .replace(' ', '')
          .replace(':', '')}`
      );
      if (points) {
        const rightPos = (elem) => elem.getBoundingClientRect().right;
        const leftPos = (elem) => elem.getBoundingClientRect().left;
        const topPos = (elem) => elem.getBoundingClientRect().top;
        const btmPos = (elem) => elem.getBoundingClientRect().bottom;

        for (let i = 0; i < points.length; i++) {
          for (let j = 0; j < points.length; j++) {
            let isOverlapping = !(
              rightPos(points[i]) < leftPos(points[j]) ||
              leftPos(points[i]) > rightPos(points[j]) ||
              btmPos(points[i]) < topPos(points[j]) ||
              topPos(points[i]) > btmPos(points[j])
            );

            if (isOverlapping && j !== i) {
              points[i].style['margin-left'] = `${i * 15}px`;
              points[i].style.width = `${bookingWidth - i * 15}px`;
            }
          }
        }
      }
    });
  };

  const handleTouchStart = (e, index, booking) => {
    if (e.target.id != booking.booking_id) {
      return;
    }

    if (!draggableBooking.includes(booking.ride_status)) {
      return;
    }

    const touch = e.touches[0];

    setStartPros({
      x: touch.clientX - dx,
      y: touch.clientY - dy,
    });

    manageLocalStorage('draggedItem', 'set', JSON.stringify(booking));

    manageLocalStorage('draggedItemTripIndex', 'set', index);
  };

  const touchEventMove = (e, bookingID, index) => {
    const ele = eleRef.current[bookingID];

    if (!ele || !startPos) {
      return;
    }
    const touch = e.touches[0];
    const dx = touch.clientX - startPos.x;
    const dy = touch.clientY - startPos.y;

    ele.style.transform = `translate(${dx}px, 0)`;

    setOffset({ dx, dy });
    const steps = Math.floor(dx / tripWidth);

    const moveToVehicleIndex = (index += steps);
    if (vehicleTrips[moveToVehicleIndex]) {
      const driverTrip = document.getElementById(`vehicle-driver-header-${moveToVehicleIndex}`);

      // make the header of where the trip is currently being dragged.
      if (driverTrip) {
        driverTrip.classList.add('touch-highlight');
      }
    }

    // this will remove bold text for the other column
    for (let i = 0; i < vehicleTrips.length; i += 1) {
      const otherDriverTrip = document.getElementById(`vehicle-driver-header-${i}`);
      if (i !== moveToVehicleIndex && otherDriverTrip) {
        otherDriverTrip.classList.remove('touch-highlight');
      }
    }
  };

  const toucheMoveEnd = (e, index, booking) => {
    const steps = Math.floor(dx / tripWidth);
    const ele = eleRef.current[booking.booking_id];

    const moveToVehicleIndex = (index += steps);

    if (steps === 0) {
      if (dx === 0) {
        // Trip is just clicked
        return setViewBookingParent(booking, index);
      }
      // this needs to be refactored, its duplicate of
      // code below

      if (ele) {
        const x = steps * 197; // where 197 is the width of a column
        ele.style.transform = `translate(${x}px, 0)`;
      }

      return removeHeaderHighlight(moveToVehicleIndex);
    }

    if (vehicleTrips[moveToVehicleIndex]) {
      vehicleTrips[moveToVehicleIndex].team_id = vehicleTrips[moveToVehicleIndex].driver_team_id;

      if (booking.type === 'autoScheduling') {
        // call create new flex trip api, means moving a trip from auto schedule data to an existing trips column
        if (vehicleTrips[moveToVehicleIndex].type !== 'autoScheduling') {
          singleFlexTrip(vehicleTrips[moveToVehicleIndex], booking);
        } else {
          updateAutoScheduleTrip(vehicleTrips[moveToVehicleIndex], booking);
        }

        removeHeaderHighlight(moveToVehicleIndex);
        return true;
      }

      if (!validateDropArea(null, vehicleTrips[moveToVehicleIndex])) {
        // return the moved trip back to its original position
        const x = 0;
        ele.style.transform = `translate(${x}px, 0)`;
        removeHeaderHighlight(moveToVehicleIndex);

        return false;
      }

      const params = {
        bookings: [booking.booking_id],
        driver_id: vehicleTrips[moveToVehicleIndex].driver_id,
        team_id: vehicleTrips[moveToVehicleIndex].driver_team_id,
        shuttle_id: vehicleTrips[moveToVehicleIndex].shuttle_id,
      };

      // /**
      //  * call api
      //  */
      updateBookingTrip(params, booking, vehicleTrips[moveToVehicleIndex]);

      // this will properly align the
      if (ele) {
        const x = steps * 197; // where 197 is the width of a column
        ele.style.transform = `translate(${x}px, 0)`;
      }
    }

    removeHeaderHighlight(moveToVehicleIndex);
  };

  const removeHeaderHighlight = (moveToVehicleIndex) => {
    // remove highlight
    const driverTrip = document.getElementById(`vehicle-driver-header-${moveToVehicleIndex}`);
    if (driverTrip) {
      driverTrip.classList.remove('touch-highlight');
    }
    setOffset({ ...{ dx: 0, dy: 0 } });
    manageLocalStorage('draggedItem', 'remove', {});
  };

  useEffect(() => {
    try {
      document.createEvent('TouchEvent');
      setIsMobile(true);
    } catch (e) {
      setIsMobile(false);
    }
  }, []);

  return (
    <>
      <div className="hours" key={parentIndex}>
        <div className="hours__item">
          <div className="item hour" id={`scroll-${hour}`}>{`${hour}`}</div>
          {vehicleTrips.map((details, index) => {
            //get only the booking under this hour

            const bookingNow = details.trips
              ? details.trips.filter((trip) => {
                  const booking = trip.bookings[0];

                  // check if booking is in this hour column
                  // timeblock trip
                  if (!booking && trip.first_pickup_time) {
                    const timeStart = dayjs(`1988/01/20 ${trip.first_pickup_time}`).format('h:mm a');
                    if (
                      timeStart.split(':')[0] === hour.split(' ')[0].split(':')[0] &&
                      timeStart
                        .split(':')[1]
                        .split(' ')[1]
                        .toLowerCase() === hour.split(' ')[1]
                    ) {
                      return trip;
                    }

                    return null;
                  }

                  if (
                    booking.scheduled_pickup_time &&
                    booking.scheduled_pickup_time.split(':')[0] === hour.split(' ')[0].split(':')[0] &&
                    booking.scheduled_pickup_time
                      .split(':')[1]
                      .split(' ')[1]
                      .toLowerCase() === hour.split(' ')[1]
                  ) {
                    return trip;
                  }

                  return null;
                })
              : [];

            return (
              <div
                key={index}
                id={parseInt(hour.split(' ')[0]) | 0}
                data={JSON.stringify({
                  shuttle_program_id: details.shuttle_program_id,
                  shuttle_name: details.shuttle_name,
                  shuttle_id: details.shuttle_id,
                  driver_id: details.driver_id,
                  team_id: details.driver_team_id,
                  type: details.type,
                  bookingNow,
                })}
                draggable={false}
                className="item"
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => handleOnDragEnter(e)}
                onDragLeave={(e) => handleOnDragLeave(e)}
                onDrop={(e) => handleDrop(e)}
                style={{ background: itemBackground }}
              >
                {bookingNow.map((trip, key) => {
                  // compute the height base on minutes
                  const {
                    bookings,
                    program_id,
                    first_pickup_longitude,
                    first_pickup_latitude,
                    last_dropoff_latitude,
                    last_dropoff_longitude,
                    drivershifts,
                    driver_action,
                  } = trip;

                  // generate HeatMap
                  let heatMapPickupData = '';
                  let heatMapDropOffData = '';
                  if (showHeatMap) {
                    if (program_id && heatMap[program_id]) {
                      if (first_pickup_longitude && first_pickup_latitude) {
                        heatMapPickupData = getHeatmapColor(heatMap[program_id], {
                          longitude: first_pickup_longitude,
                          latitude: first_pickup_latitude,
                        });
                      }
                      if (last_dropoff_latitude && last_dropoff_longitude) {
                        heatMapDropOffData = getHeatmapColor(heatMap[program_id], {
                          longitude: last_dropoff_longitude,
                          latitude: last_dropoff_latitude,
                        });
                      }
                    }
                  }

                  // end HeatMap

                  let booking = bookings[0];

                  let scheduled_pickup_time;
                  let scheduled_dropoff_time;

                  if ((booking && drivershifts.length === 0 && driver_action === null) || booking?.rec) {
                    booking.blockType = 'trip';
                    booking.heatMapDropOffData = heatMapDropOffData;
                    booking.heatMapPickupData = heatMapPickupData;
                    scheduled_pickup_time = booking.scheduled_pickup_time;
                    scheduled_dropoff_time = booking.scheduled_dropoff_time;
                  } else if (driver_action && driver_action !== null) {
                    scheduled_pickup_time = dayjs('1988/01/20' + bookingNow[key].first_pickup_time).format('h:mm A');
                    scheduled_dropoff_time = dayjs('1988/01/20' + bookingNow[key].last_dropoff_time).format('h:mm A');
                    booking = {
                      scheduled_pickup_time,
                      scheduled_dropoff_time,
                      shuttle_id: details.shuttle_id,
                      driver_id: details.driver_id,
                      driver_team_id: details.driver_team_id,
                      blockType: 'driverAction',
                      booking_id: Math.random(),
                      ride_status: bookings.length > 0 ? bookings[0].ride_status : 10,
                      admin_note:
                        booking.admin_note && booking.admin_note !== '' ? booking.admin_note : 'Driver Action',
                      passenger_firstname: 'DRIVER ACTION',
                      passenger_lastname: '',
                      mobility_assistance: [],
                      payment: '',
                      ride_fee: 0,
                      trip: bookingNow[key],
                      location: booking.first_pickup_address,
                      background: 'driverActionStatus',
                    };
                  } else {
                    const isLunchBreak = bookingNow[key].drivershifts?.length > 0;
                    // trip block or lunch break
                    scheduled_pickup_time = dayjs('1988/01/20' + bookingNow[key].first_pickup_time).format('h:mm A');
                    scheduled_dropoff_time = dayjs('1988/01/20' + bookingNow[key].last_dropoff_time).format('h:mm A');
                    booking = {
                      scheduled_pickup_time,
                      scheduled_dropoff_time,
                      shuttle_id: details.shuttle_id,
                      driver_id: details.driver_id,
                      driver_team_id: details.driver_team_id,
                      blockType: isLunchBreak ? 'lunchBreak' : 'timeBlock',
                      booking_id: Math.random(),
                      ride_status: isLunchBreak ? (bookings.length > 0 ? bookings[0].ride_status : 8) : 9,
                      passenger_firstname: isLunchBreak ? 'LUNCH BREAK' : 'TIME BLOCK',
                      passenger_lastname: '',
                      mobility_assistance: [],
                      payment: '',
                      ride_fee: 0,
                      trip: bookingNow[key],
                      admin_note: bookingNow[key].notes,
                      background: isLunchBreak ? 'break' : '',
                    };
                  }

                  // use the pickup-time as css top measurement
                  const top = `${ConvertHours(scheduled_pickup_time)[1] * converterToHeight}px`;

                  // use the pickup-dropoff time as height
                  let height = ComputeHeight(scheduled_pickup_time, scheduled_dropoff_time, converterToHeight);

                  // if Heat Map is enabled, make sure that the trip height
                  // is equal or greater than 100px

                  if (parseInt(height, 10) < 10) {
                    height = '13px';
                  } else {
                    height = `${height}px`;
                  }
                  let statusColor = parseInt(booking.ride_status);
                  if (booking.driver_arrival_dropoff_time && statusColor === 6) {
                    statusColor = 11;
                  }
                  // set color of booking;
                  let color = riseStatus[statusColor];

                  if ((booking.ride_status === 1 || booking.ride_status === 2) && booking.driver_arrival_time) {
                    color = 'arrived';
                  } else if (booking.ride_status === 1 && !booking.driver_arrival_time && booking.driver_enroute_time) {
                    color = 'en-route';
                  }

                  let specialBackground = '';
                  if (booking.background) {
                    specialBackground = `${booking.background} ${booking.background}-${color}`;
                  }

                  // set border if firstname or lastname containes searchvalue
                  let isSearched = false;
                  const fullName = `${booking.passenger_firstname} ${booking.passenger_lastname}`;
                  if (searchValue !== '' && fullName.toLowerCase().includes(searchValue.toLowerCase())) {
                    isSearched = true;
                  }

                  const isTouchDrag = !isSwipe && draggableBooking.includes(booking.ride_status);

                  return (
                    /**this div is the trip box*/
                    <div
                      ref={(element) => (eleRef.current[booking.booking_id] = element)}
                      id={booking.booking_id}
                      key={`${booking.booking_id} - ${booking.ride_status} - ${height}`}
                      // draggable={bookingDate >= toDay} // this will restrict the dragging
                      draggable={
                        draggableBooking.includes(booking.ride_status) && !isNewScheduler && !booking.recommendation
                      }
                      data={JSON.stringify(booking)}
                      onClick={() => {
                        if (!booking.recommendation) {
                          setViewBookingParent(booking, index);
                        }
                      }}
                      // the replace is based on the clock structure i.e 10:30 AM
                      // after replace this will be box-index-1030AM
                      className={`box-${index}-${hour
                        .toString()
                        .replace(' ', '')
                        .replace(':', '')} booking__details
                      ${color}
                      ${specialBackground}
                      ${isSearched && 'searched'} trip-box-container`}
                      style={{
                        top,
                        height,
                        display: 'block',
                        overflow: isShowMore === booking.booking_id ? 'auto' : 'hidden',
                        userSelect: 'none',
                        touchAction: 'none',
                      }}
                      onDragOver={(e) => {
                        handleHideBooking(e);
                      }}
                      onDragStart={(e) => {
                        e.target.style.opacity = 0.5;
                        manageLocalStorage('draggedItem', 'set', JSON.stringify(booking));

                        manageLocalStorage('draggedItemTripIndex', 'set', index);
                      }}
                      onDragEnd={(e) => {
                        e.target.style.opacity = 1;
                        e.target.style.display = 'block';
                        manageLocalStorage('draggedItem', 'remove', {});
                      }}
                      onMouseEnter={() => setIsShowMore(booking.booking_id)}
                      onMouseLeave={() => setIsShowMore()}
                    >
                      {isMobile && booking.blockType === 'trip' && (
                        <div
                          id={booking.booking_id}
                          draggable={draggableBooking.includes(booking.ride_status)}
                          className="touch-area"
                          onTouchStart={isTouchDrag ? (e) => handleTouchStart(e, index, booking) : () => {}}
                          onTouchMove={isTouchDrag ? (e) => touchEventMove(e, booking.booking_id, index) : () => {}}
                          onTouchEnd={isTouchDrag ? (e) => toucheMoveEnd(e, index, booking) : () => {}}
                        />
                      )}

                      {/** HEAT MAP SHOW */}
                      {showHeatMap && booking.heatMapPickupData && (
                        <div className="heat__map_container">
                          <div
                            className="heat__map_top"
                            style={{
                              background: booking.heatMapPickupData?.color
                                ? `#${booking.heatMapPickupData.color}`
                                : 'none',
                            }}
                          >
                            {booking?.heatMapPickupData?.label}, {booking.pickup_city}
                          </div>
                          <div
                            className="heat__map_bottom"
                            style={{
                              background: booking.heatMapDropOffData?.color
                                ? `#${booking.heatMapDropOffData.color}`
                                : 'none',
                            }}
                          >
                            {booking?.heatMapDropOffData?.label}, {booking.dropoff_city}
                          </div>
                        </div>
                      )}
                      {isShowMore === booking.booking_id && (
                        <div>
                          <span>{trip.first_pickup_address}</span>
                          <br />
                          <span>{trip.last_dropoff_address}</span>
                        </div>
                      )}
                      <div
                        style={{
                          paddingTop: showHeatMap ? height : '',
                          position: 'relative',
                          userSelect: 'none',
                          touchAction: 'none',
                        }}
                      >
                        <div style={{ display: 'inline' }}>
                          {fullName}
                          {booking.mobility_assistance.includes('Wheelchair') && (
                            <span className="mobility__icon">
                              <img
                                src="/resources/images/icon_wheelchair_white.png"
                                alt=""
                                style={{ width: '20px', height: '20px' }}
                              />
                            </span>
                          )}
                          {booking.mobility_assistance.includes('Stretcher') && (
                            <span className="mobility__icon">
                              <img
                                src="/resources/images/icon_stretcher_white.png"
                                alt=""
                                style={{ width: '20px', height: '20px' }}
                              />
                            </span>
                          )}
                        </div>
                        <br />
                        <div style={{ display: 'inline' }}>
                          {scheduled_pickup_time} - {scheduled_dropoff_time}
                        </div>
                        {['timeBlock', 'driverAction'].includes(booking.blockType) && (
                          <>
                            <br />
                            {booking.admin_note}
                          </>
                        )}
                        <br />
                        {booking.mobility_assistance.map((mobility, index) => {
                          return (
                            <div key={index} style={{ display: 'inline' }}>
                              {mobility}
                            </div>
                          );
                        })}
                        {isShowMore === booking.booking_id && (
                          <div>
                            <span>{booking.pickup_address}</span>
                            <br />
                            <span>{booking.dropoff_address}</span>
                          </div>
                        )}
                        {['lunchBreak', 'driverAction'].includes(booking.blockType) && <div className="whiteBG" />}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

/**
 * Confirmation message component for
 * removing dispatched bookings
 * @returns
 */
const ConfirmationModal = ({ removeDispatchedBooking, setRemoveBookingId }) => {
  return (
    <div className="confirmation__modal-container">
      <div className="confirmation__modal-content">
        <div className="confirmation__modal-message">
          This trip has been dispatched. Do you want to remove it from the dispatch list?
        </div>
        <div className="confirmation__modal-buttons">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setRemoveBookingId(null);
            }}
          >
            Cancel
          </button>
          &nbsp;&nbsp;&nbsp;
          <button type="button" className="btn btn-primary" onClick={removeDispatchedBooking}>
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

const DriverTeamName = ({ driver, handleHover, updatedAddedVehicles, onClickDriverTxt, index, handleMouseLeave }) => {
  const uniqueKey = `${driver.driver_id}-${index}`;
  const uniqueKey2 = `${driver.shuttle_id}-${index}`;
  return (
    <>
      {driver.trips.length > 0 ? (
        <div onMouseLeave={() => handleMouseLeave(uniqueKey2)} onMouseEnter={() => handleHover(uniqueKey2)}>
          {driver.shuttle_name}
        </div>
      ) : (
        <div className="update-shuttle" onClick={() => updatedAddedVehicles(driver, true, index)}>
          {driver.shuttle_name}
        </div>
      )}

      <VehicleInfo vehicle={driver} uniqueKey={uniqueKey2} />
      <DriverInfo driver={driver} uniqueKey={uniqueKey} onMouseLeave={handleMouseLeave} onMouseEnter={handleHover} />
      {driver.driver_firstname || driver.driver_lastname ? (
        driver.trips.length > 0 ? (
          <div onMouseLeave={() => handleMouseLeave(uniqueKey)} onMouseEnter={() => handleHover(uniqueKey)}>{`${
            driver.driver_firstname
          } ${driver.driver_lastname}`}</div>
        ) : (
          <div
            className="update-driver"
            onClick={() => updatedAddedVehicles(driver, false, index)}
            onMouseEnter={() => handleHover(driver.driver_id)}
          >
            {`${driver.driver_firstname} ${driver.driver_lastname}`}
          </div>
        )
      ) : driver.driver_team_id && driver.driver_team_name && driver.trips.length > 0 ? (
        <div className="driver-team-title-container">
          <div
            className="driver-team-title"
            onMouseEnter={() => handleHover(driver.driver_team_name)}
            onMouseLeave={() => handleMouseLeave(driver.driver_team_name)}
          >
            {driver.driver_team_name}
          </div>
          <DriverTeamListView
            drivers={driver}
            driverTeam={driver.driver_team_name}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleHover}
          />
        </div>
      ) : driver.driver_team_id && driver.driver_team_name ? (
        <div className="driver-team-title-container">
          <div
            className="driver-team-title add-driver"
            onClick={() => onClickDriverTxt(driver.shuttle_id, index, true)}
            onMouseEnter={() => handleHover(driver.driver_team_name)}
            onMouseLeave={() => handleMouseLeave(driver.driver_team_name)}
          >
            {driver.driver_team_name}
          </div>
          <DriverTeamListView
            drivers={driver}
            driverTeam={driver.driver_team_name}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleHover}
          />
        </div>
      ) : (
        <div className="add-driver" onClick={() => onClickDriverTxt(driver.shuttle_id, index, false)}>
          +Driver
        </div>
      )}
    </>
  );
};

/**
 * get the hour from a given string time
 * @param {'7 am' or 7:00 am} hour
 * @returns 7 as int
 */
const getHourFromString = (hour) => {
  if (hour.includes(':')) {
    return parseInt(hour.split(':')[0]);
  } else {
    return parseInt(hour.split(' ')[0]);
  }
};

//convert 1:30 PM => [1,30,PM]
const ConvertHours = (hour) => {
  const h = parseInt(hour.split(':')[0]);
  const min = parseInt(hour.split(':')[1].split(' ')[0]);
  const period = hour
    .split(':')[1]
    .split(' ')[1]
    .toLowerCase();

  return [h, min, period];
};

/**
 * Compute the height of a particular booking
 * Based on the startTIme(hour pickup time) and the endTime(minutes of pickupTime)
 * @param {*} startTime
 * @param {*} endTime
 * @param {*} percent
 * @returns
 */
const ComputeHeight = (startTime, endTime, percent) => {
  let height = 0;

  let day = 20;

  if (startTime.endsWith('PM') && endTime.endsWith('AM')) {
    day = 21;
  }

  const startDate = dayjs(`01/20/1988 ${startTime}`);
  const endDate = dayjs(`01/${day}/1988 ${endTime}`);

  const dateduration = dayjs.duration(endDate.diff(startDate));
  const hours = dateduration.hours() + dateduration.minutes() / 60;

  height = hours * 60 * percent;

  return Math.floor(height);
};

const removeCloneDiv = () => {
  const clone = document.getElementById('clone');
  if (clone) {
    clone.remove();
  }
};

const manageLocalStorage = (item, eventType, value = null) => {
  if (eventType === 'get') {
    return localStorage.getItem(item);
  }

  if (eventType === 'set') {
    return localStorage.setItem(item, value);
  }

  if (eventType === 'remove') {
    return localStorage.removeItem(item);
  }
};

const DriverTeamListView = ({ drivers, driverTeam, onMouseEnter = () => {}, onMouseLeave = () => {} }) => {
  return (
    <div
      className="driver-names"
      id={driverTeam}
      onMouseEnter={() => onMouseEnter(driverTeam)}
      onMouseLeave={() => onMouseLeave(driverTeam)}
    >
      {drivers.driver_team &&
        drivers.driver_team
          .sort((a, b) => {
            if (a.driver_firstname === b.driver_firstname) {
              return a.driver_lastname.localeCompare(b.driver_lastname);
            }
            return a.driver_firstname.localeCompare(b.driver_firstname);
          })
          .map((driver, index) => {
            const uniqueKey = `${driver.driver_id}${index}-team`;
            return (
              <React.Fragment key={uniqueKey}>
                <DriverInfo
                  driver={driver}
                  uniqueKey={uniqueKey}
                  onMouseEnter={() => onMouseEnter(uniqueKey)}
                  onMouseLeave={() => onMouseLeave(uniqueKey)}
                />
                <div onMouseEnter={() => onMouseEnter(uniqueKey)} onMouseLeave={() => onMouseLeave(uniqueKey)}>{`${
                  driver.driver_firstname
                } ${driver.driver_lastname}`}</div>
              </React.Fragment>
            );
          })}
    </div>
  );
};

export default SchedulerComponent;
