/* eslint-disable */

import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Close from '../../../components/SvgIcons/Close';
import styles from './styles.module.scss';
import TripsOnMap from './trips-on-googlemap';

const { host, protocol } = window.location;
const dropOffUrl = `${protocol}//${host}/resources/images/dropoff_pin_same-size.png`;
const pickupOffUrl = `${protocol}//${host}/resources/images/pickup_pin_same-size.png`;
const RemoveIcon = `${protocol}//${host}/resources/images/Remove.svg`;

const TripReorder = ({ close, bookings: propsBookings, handleNext }) => {
  const [trips, setTrips] = useState([]);
  const [bookings, setBookings] = useState([]);

  // Tracks the index of the dragged trip
  const [draggingIndex, setDraggingIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (e, index) => {
    console.log('called');
    e.preventDefault();
    let newTrips = [...trips];
    const draggedTrip = newTrips[draggingIndex];

    // Remove the dragged trip from its old position
    newTrips.splice(draggingIndex, 1);

    // Insert it at the new index
    newTrips.splice(index, 0, draggedTrip);

    newTrips = [...validateTripsOrder(newTrips)];

    setTrips(newTrips);
    setDraggingIndex(index);
  };

  const handleDrop = () => {
    setTrips([...trips]);
    setDraggingIndex(null);
  };

  // On drag stop, ensure that each drag item is properly ordered with its pair.
  // When an item is pickup type, its paired drop-off point should be positioned below it, not above, and vice versa.
  const validateTripsOrder = (validateTrips) => {
    let newTrips = [...validateTrips];
    if (validateTrips[draggingIndex]) {
      const bookingId = validateTrips[draggingIndex].bookings[0].id;
      const stopType = validateTrips[draggingIndex].bookings[0].stop_type;

      // this is the index of dragged item after drop is done
      const indexAfter = validateTrips.findIndex(
        ({ bookings }) => bookings[0].stop_type === stopType && bookings[0].id === bookingId
      );
      // this is the index of the paired  dragged
      const indexOfPair = validateTrips.findIndex(
        ({ bookings }) => bookings[0].stop_type !== stopType && bookings[0].id === bookingId
      );

      if (validateTrips[indexAfter] && validateTrips[indexOfPair]) {
        if (stopType === 'pickup' && indexAfter > indexOfPair) {
          newTrips = moveArrayElement([...validateTrips], indexOfPair, indexAfter + 1);
          setTrips([...newTrips]);
        } else if (stopType === 'dropoff' && indexAfter < indexOfPair) {
          newTrips = moveArrayElement([...validateTrips], indexOfPair, indexAfter);
        }
      }
    }
    return newTrips;
  };

  const moveArrayElement = (arr, fromIndex, toIndex) => {
    // Adjust for -1 (move to end) and out-of-bounds index
    if (toIndex < 0) {
      toIndex = arr.length - 1;
    } else if (toIndex >= arr.length) {
      toIndex = arr.length - 1;
    }

    // Remove the element from the original index
    const element = arr.splice(fromIndex, 1)[0];

    // Insert it at the new index
    arr.splice(toIndex, 0, element);

    return arr;
  };

  const handleLockThisRoute = () => {
    const combinedDataByAddress = Object.values(
      trips.reduce((acc, { address, bookings }) => {
        if (!acc[address]) {
          acc[address] = { address, bookings: [] };
        }
        acc[address].bookings = acc[address].bookings.concat(bookings);
        return acc;
      }, {})
    );

    handleNext(combinedDataByAddress);
  };

  useEffect(() => {
    setTrips(propsBookings);
    setBookings(propsBookings);
  }, [propsBookings]);

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.tripReorderContainer}>
            <div className={styles.mapContainer}>
              <TripsOnMap bookings={bookings} />
            </div>
            <div className={styles.tripsContainer}>
              <div className={styles.header}>
                <div>Shared Ride Setup</div>
                <div className={styles.closeIcon}>
                  <IconButton onClick={close}>
                    <Close className={styles['close-icon']} />
                  </IconButton>
                </div>
              </div>

              <div className={`mt-3 ${styles.tripReorder}`}>
                <div className={styles.subHeader}>Step 1: Optimize route</div>
                <p className="mt-1">
                  We&apos;ve optimized the route and found the shortest distance. You can either take the result or
                  adjust it further yourself by dragging the pickup or drop-off blocks up and down.
                </p>
                <p className="mt-3">
                  Once you&apos;re satisfied with the route, just proceed to the next step. Please ignore the time at
                  this step. You will adjust the time at the next step.
                </p>

                <ul className={`mt-4 ${styles.tripList}`}>
                  {trips.map((trip, index) => {
                    const booking = trip.bookings[0];
                    const isPickup = booking.stop_type === 'pickup';
                    const time = isPickup ? booking.scheduled_pickup_time : booking.scheduled_dropoff_time;
                    const tripTime = new Date(time).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    });

                    return (
                      <li
                        key={`${trip.id}${index}`}
                        draggable
                        onDragStart={() => handleDragStart(index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDrop={handleDrop}
                        className={styles.tripItem}
                      >
                        <div className={styles.tripIcon}>
                          <img src={isPickup ? pickupOffUrl : dropOffUrl} alt="tripIcon" />
                        </div>
                        <div className={styles.tripDetails}>
                          <div className={styles.tripName}>
                            {tripTime} {booking.passenger_firstname} {booking.passenger_lastname}
                          </div>
                          <div className={styles.tripAddress}>{trip.address}</div>
                        </div>
                        <div className={styles.removeIcon}>
                          <img src={RemoveIcon} alt="remove" />
                        </div>
                        <div className={styles.grabIcon}>
                          <img src="/resources/images/vertical-grab.svg" alt="grab" />
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="mt-4">
                  <button className="btn btn-secondary" onClick={close}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" style={{ float: 'right' }} onClick={handleLockThisRoute}>
                    Lock this route
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripReorder;
