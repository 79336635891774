/* eslint-disable */

import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Close from '../../../components/SvgIcons/Close';
import styles from './styles.module.scss';
import CustomInput from '../../../components/Elements/TextInput/CustomInput';

const { host, protocol } = window.location;
const dropOffUrl = `${protocol}//${host}/resources/images/dropoff_pin_same-size.png`;
const pickupOffUrl = `${protocol}//${host}/resources/images/pickup_pin_same-size.png`;
const RemoveIcon = `${protocol}//${host}/resources/images/Remove.svg`;

const OptimizeTrip = ({ close, optimizedTime, handelBack }) => {
  const [trips, setTrips] = useState([]);

  // Tracks the index of the dragged trip
  const [draggingIndex, setDraggingIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    const newTrips = [...trips];
    const draggedTrip = newTrips[draggingIndex];

    // Remove the dragged trip from its old position
    newTrips.splice(draggingIndex, 1);

    // Insert it at the new index
    newTrips.splice(index, 0, draggedTrip);

    setTrips(newTrips);
    setDraggingIndex(index);
  };

  const handleDrop = () => {
    setDraggingIndex(null);
  };

  const handleLockThisRoute = () => {};

  useEffect(() => {
    if (optimizedTime && optimizedTime.stop_order) {
      setTrips(optimizedTime.stop_order);
    }
  }, [optimizedTime]);

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.tripReorderContainer}>
            <div className={styles.mapContainer}>
              <div className={styles.header}>Shared Trip Setup</div>
              <div className={`mt-4 ${styles.subHeader}`}>Optimize route</div>
              <div className={`pl-4 mt-4 ${styles.paragraph}`}>
                Update Arrival Time at Pickup including load/unload time
              </div>
              <ul className={`${styles.tripList}`}>
                {trips.map((trip, index) => {
                  const booking = trip.bookings[0];
                  const isPickup = booking.stop_type === 'pickup';
                  const time = isPickup ? booking.scheduled_pickup_time : booking.scheduled_dropoff_time;
                  const tripTime = new Date(time).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  });

                  return (
                    <li
                      key={trip.id}
                      draggable
                      onDragStart={() => handleDragStart(index)}
                      onDragOver={(e) => handleDragOver(e, index)}
                      onDrop={handleDrop}
                      className={styles.optimizeTime}
                    >
                      <div className={styles.pickupTime}>{tripTime}</div>
                      <div className={styles.tripItem}>
                        <div className={styles.tripIcon}>
                          <img src={isPickup ? pickupOffUrl : dropOffUrl} alt="tripIcon" />
                        </div>
                        <div className={styles.tripDetails}>
                          <div className={styles.tripName}>
                            {tripTime} {booking.passenger_firstname} {booking.passenger_lastname}
                          </div>
                          <div className={styles.tripAddress}>{trip.address}</div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={styles.tripsContainer}>
              <div className={styles.header}>
                <div>&nbsp;</div>
                <div className={styles.closeIcon}>
                  <IconButton onClick={close}>
                    <Close className={styles['close-icon']} />
                  </IconButton>
                </div>
              </div>

              <div className={`mt-3 ${styles.tripReorder}`}>
                <div className={styles.subHeader}>Step 2: Optimize pickup time</div>
                <p className={`mt-1 ${styles.paragraph}`}>
                  Based on the estimate travel time, adjust the loading and unloading time, and the first pickup time to
                  ensure the on-time performance before creating the shared ride.
                </p>

                <div className={`mt-4 ${styles.tripList}`}>
                  <div className={styles.inputsContainer}>
                    <CustomInput label="Estimated total travel time" value={optimizedTime?.travel_distance} />
                    <CustomInput label="Load or unload time, minutes" value={optimizedTime?.travel_time} />
                  </div>
                  <div className={styles.inputsContainer}>
                    <CustomInput label="Set the first pickup time" value="" />
                    <button className="btn btn-outline-primary">Update all pickup time</button>
                  </div>
                </div>
              </div>
              <div className={`mt-4 text-right ${styles.optimizeButtons}`}>
                <button className="btn btn-secondary" onClick={handelBack}>
                  Back
                </button>
                <button className="ml-2 btn btn-primary" onClick={handleLockThisRoute}>
                  Create shared trip
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptimizeTrip;
